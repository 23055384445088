import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useRef, createContext } from "react";
import Title from "../../../components/title/Title";
import { Links } from "../../../constants/links";
import "../../global.css";
import { Employee, Employees } from "../../../store/Models/Employee";
import { SelectIndex } from "../../message/SelectMessageType";
import { Departments } from "../../../store/Models/Department";
import ImageViewer from "../../../components/ImageViewer";
import EmployeeTracker from "./EmployeeTracker";
import EmployeeCharts from "./EmployeeCharts";
import { selecteeEmployees } from "../../../store/store";
import { DashboardContext } from "../../dashboard/dashboard";
interface StateType {
  employeeID: number;
}
export class ContextProps {
  employee: Employee;
  displayMap: any;
  setter: Function;
}

const EmployeeContext = createContext(new ContextProps());
const SingleEmployee = () => {
  const { state } = useLocation();

  console.log(state);
  const [isBlock, setBlock] = useState(false);
  let employeeID = (state as StateType).employeeID;
  const employee = Employees.getSingletonEmployees().getByID(employeeID);
  employee.isChecked=true;
  selecteeEmployees.setList([employee]);
  const context = new ContextProps();
  context.employee = employee;
  context.displayMap = isBlock;
  context.setter = setBlock;
  return (
    <div>
      <Title titleName="Employee Profile" backLink={`${Links.dashboard}${Links.employeeList}`} />
      <EmployeeContext.Provider value={context}>
        <div
          style={{
            position: "absolute",
            marginTop: 20,
            pointerEvents: isBlock ? "none" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <LeftWidget />
            <RightWidget />
            </div>
            <div style={{ display: "block", width: "100%" }}>
              <EmployeeCharts context={EmployeeContext} />
          </div>
        </div>
        {isBlock && <EmployeeTracker context={EmployeeContext} />}
      </EmployeeContext.Provider>
    </div>
  );
};

export default SingleEmployee;

const LeftWidget = () => {
  let employee = useContext(EmployeeContext).employee;
  const x= useContext(DashboardContext);
  let object = useContext(EmployeeContext);
  return (
    <div
      style={{
        position: "relative",
        width: "190px",
        display: "inline-block",
        // border:"black solid 1px"
      }}
    >
      <div style={{ textAlign: "center" }}>
        <ImageViewer employee={employee} />
      </div>
      <div
        style={{
          width: "173px",
          textAlign: "center",
        }}
      >
        <p
          style={{
            marginTop: 8,
            fontFamily: "Exo 2",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16pt",
            lineHeight: "19px",
            color: "#2E3133",
            marginBottom: 1,
          }}
        >
          {employee.name}
        </p>
        <p
          style={{
            position: "relative",
            marginTop: "0.8em",
            fontFamily: "Exo 2",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12pt",
            lineHeight: "19px",
            color: "#2E3133",
          }}
        >
          {employee.position}
        </p>
      </div>
      <div style={{ marginTop: 20 }}>
        <button
          className="buttons"
          style={{
            width: "85%",
            position: "relative",
            marginBottom: 10,
          }}
          onClick={(event) => {
            object.setter(!object.displayMap);
          }}
        >
          Track {employee.name}
        </button>
        <button
          className="buttons"
          style={{
            width: "85%",
            position: "relative",
            color: "#60a7a3",
            backgroundColor: "white",
            border: "#60a7a3 solid 1px",
          }}

          onMouseUp={(event) => {
            // setActive(false);
            x.setDisplay("block");
          }}
        >
          Add Holiday
        </button>
      </div>
    </div>
  );
};

const RightWidget = () => {
  const employee: Employee = useContext(EmployeeContext).employee;
  let currentID = employee.departmentID;
  const nameRef = useRef(null);
  const positionRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const salaryRef = useRef(null);

  let [departments, setDepartments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeps = async () => {
      setDepartments(
        await Departments.getDepartmentSingleton().getDepartments()
      );
    };
    fetchDeps();
  }, []);

  const updateEmp = async () => {
    const newEmp = new Employee(
      employee.id,
      nameRef.current.value,
      emailRef.current.value,
      phoneRef.current.value,
      passwordRef.current.value,
      departments[currentID],
      positionRef.current.value
    );
    newEmp.departmentID = currentID;
    newEmp.salary = salaryRef.current.value;
    try {
      await newEmp.update();
      // navigate(`${Links.dashboard}${Links.employeeList}`);
      alert("Updated!");
    } catch (E) {
      alert("ERROR!");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "75%",
        display: "inline-block",
        margin: 0,
      }}
    >
      <div>
        <h2 style={{ display: "inline" }}>Personal Info</h2>
        <button
          style={{
            background: "none",
            border: "none",
            position: "relative",
            float: "right",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            textDecorationLine: "underline",

            color: "#D86F2B",
          }}
          onClick={async (event) => {
            try {
              await employee.delete();
              navigate(`${Links.dashboard}${Links.employeeList}`);
            } catch (E) {
              console.log(E);
              alert("ERROR!");
            }
          }}
        >
          Delete {employee.name}
        </button>

        <div
          style={{
            marginTop: 20,
            border: "#e5e5e5 solid 1px",
            borderRadius: 10,
            padding: "1em .5em 1.5em .5em",
          }}
        >
          <Grid container columnSpacing={3} rowSpacing={4} paddingRight="1em">
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Name"
                defaultValue={employee.name}
                ref={nameRef}
                title="name"
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Position"
                defaultValue={employee.position}
                ref={positionRef}
                title="position"
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Email"
                defaultValue={employee.email}
                ref={emailRef}
                title="email"
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Password"
                ref={passwordRef}
                title="password"
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Phone"
                defaultValue={employee.phone}
                ref={phoneRef}
                title="phone"
              />
            </Grid>
            <Grid item xs={8} md={6} textAlign="center">
              {departments.length > 0 && (
                <SelectIndex
                  data={departments}
                  name="Departments"
                  setter={(id) => {
                    currentID = id;
                  }}
                  defaultValue={currentID}
                />
              )}
            </Grid>
            <Grid item xs={8} md={6}>
              <input
                className="text-input"
                placeholder="Salary"
                type="number"
                ref={salaryRef}
                defaultValue={employee.salary}
                title="salary"
              />
            </Grid>

            <Grid item xs={12} md={5.5} textAlign="end">
              <button
                style={{
                  position: "relative",
                  backgroundColor: "#60A7A3",
                  color: "white",
                  padding: "0.8em 1.5em 0.8em 1.75em",
                  border: "none",
                  fontSize: "13pt",
                  fontWeight: "bold",
                }}
                onClick={updateEmp}
              >
                Save Changes
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
