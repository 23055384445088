import { makeAutoObservable } from "mobx"

export class SearchItem{
    text: string

    constructor(){
        makeAutoObservable(this);
    }
    setText(value){this.text= value;}
    getText(){return this.text;}
    emptyText(){this.setText('');}
}