import React from 'react'
import Title from '../../components/title/Title'
import MessagesTable from './Table'

const MessagesList = () => {
  return (
    <div>
      <Title titleName={"Messages List"}/>
      <MessagesTable />
    </div>
  )
}


export default MessagesList
