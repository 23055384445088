import { makeAutoObservable } from "mobx"

export class FirstDashboard{
    totalEmployees:number= 0;
    absentPeople:number= 0;
    inZones:number= 0;
    outZones: number= 0;

    constructor(){
        makeAutoObservable(this);
    }

    setTotalEmps(num){
        console.log(num);
        this.totalEmployees= num;
    }
    setAbsents(num){this.absentPeople= num;}
    setInZones(num){this.inZones= num;}
    setOutZones(num){this.outZones= num;}

    getTotalEmps() {return this.totalEmployees;}
    getAbsents() {return this.absentPeople;}
    getInZones() {return this.inZones;}
    getOutZones() {return this.outZones;}
}

export const firstDashboardData= new FirstDashboard();