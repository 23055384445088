import { JSXElementConstructor, ReactElement } from "react";
import { firstDashboardData } from "../../../store/Dashboards/FirstDashboard";
import { secondDashboardData } from "../../../store/Dashboards/SecondDashboard";
import {
  AbsenceEmployeeComponent,
  InZoneComponent,
  OutZoneComponent,
  TotalEmployeesComponent,
} from "./FirstChart/AdminBox";
import PieChart from "./FirstChart/PieChart";
import { InZoneLate, InZoneWorkAway, OutZoneHoliday, OutZoneNoExcuse } from "./SecondChart/AdminBox";
import SecondPieChart from "./SecondChart/PieChart";

export abstract class CompleteDashboard {
  private firstAdminBoxes: Function[];
  private secondAdminBoxes: Function[];
  private pieChart: Function;

  constructor(firstBoxes, secondBoxes, pieChart) {
    this.firstAdminBoxes = firstBoxes;
    this.secondAdminBoxes = secondBoxes;
    this.pieChart = pieChart;
  }
  public getFirstAdminBoxes(): Function[] {
    return this.firstAdminBoxes;
  }
  public getSecondAdminBoxes(): Function[] {
    return this.secondAdminBoxes;
  }
  public abstract getPieChart(): ReactElement;
}

export class DailyDashboard extends CompleteDashboard {
  constructor() {
    super(
      [TotalEmployeesComponent, AbsenceEmployeeComponent],
      [InZoneComponent, OutZoneComponent],
      PieChart
    );
  }

  public getPieChart(): ReactElement<any, string | JSXElementConstructor<any>> {
    return <PieChart dashboardData={firstDashboardData} />;
  }
}

export class ZoneTypesDashboard extends CompleteDashboard {
  constructor() {
    super(
      [InZoneLate, InZoneWorkAway],
      [OutZoneHoliday, OutZoneNoExcuse],
      PieChart
    );
  }

  public getPieChart(): ReactElement<any, string | JSXElementConstructor<any>> {
    return <SecondPieChart dashboardData={secondDashboardData} />;
  }
}
