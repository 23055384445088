import { makeAutoObservable } from "mobx"

export class SelectedEmployees{
    list=[];

    constructor(){
        makeAutoObservable(this);
    }
    setList(list){
        this.list= list.filter((elem)=>{
           return elem.isChecked;
        });
        for(let i of this.list)
            console.log(i.name);
        }
    getList(){
        return this.list;
    }
}