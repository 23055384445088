import { adminData } from '../store/store';
import {createEmployeesFromList} from './postRequests';

export const NOT_FOUND_URL= '/';
export function isDefined(object){
    return typeof(object) !== 'undefined';
}

export const isValidNumber=(num)=>{
    return num > 0;
}

export const createMultipleEmployees=async (data)=>{
    data= data.split('\n');
    console.log(data);
    let newData=[]
    for (let row of data){
        let subData= row.split(",");
        if(subData[0] ==='')
            break;
        newData.push({name:subData[0], phone: parseInt(subData[1]), position:subData[2], department_id: parseInt(subData[3]), password:subData[4]
        , email:subData[5], company_id:parseInt(subData[6])})
    }
    await createEmployeesFromList(newData);
    console.log(newData);
}

export const isTokenDefined=()=>{
    console.log(adminData.getToken());
    return isDefined(adminData.getToken());
}

export const getFixedValue=(x, precision=2)=>{
    return Number.parseFloat(x).toFixed(precision);
}