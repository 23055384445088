import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Links } from "../../constants/links";
import {
  StyledTableCell,
  StyledTableRow,
  TableInnerTitles,
} from "../../components/TableTitle";
import { TableHead } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { selecteeEmployees } from "../../store/store";
import { Employee, Employees } from "../../store/Models/Employee";
import { SearchItem } from "../../store/SearchItem";
import { CheckedInEmployees } from "../../store/Models/CheckedInEmployees";

const Context = createContext(null);

async function getEmployeesData() {
  await Employees.getSingletonEmployees().getEmployees();
  await CheckedInEmployees.getSingletonInstance().getCheckedInEmployees();
  return Employees.getSingletonEmployees().filterByCheckedIn();
}

type Data = {
  item: SearchItem;
};

export const EmployeeListTable = observer((data: Data) => {
  const [emps, setEmps] = useState([]);
  const [marked, setMarked] = useState(false);
  const setNewSample = (state) => {
    state.length = 0;
    let newList = [];
    if (!Array.isArray(emps)) {
      newList = emps;
      return newList;
    } else {
      try {
        newList = emps.filter((elem: Employee) => {
          elem.isChecked = false;
          if (data.item.getText().length === 0) return true;
          let text = data.item.getText().toLowerCase();
          if (text === "active") {
            return CheckedInEmployees.getSingletonInstance().include(elem);
          } else if (text === "absent") {
            return !CheckedInEmployees.getSingletonInstance().include(elem);
          } else {
            return (
              elem.name.toLowerCase().includes(text) ||
              elem.position.toLowerCase().includes(text) ||
              elem.departmentName.toLowerCase().includes(text) ||
              elem.phone.toLowerCase().includes(text)
            );
          }
        });

        return newList;
      } catch (E) {
        console.log(E);
      }
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET":
        state = setNewSample(state);
        break;
      case "MARK_ALL":
        for (let i of state) i.isChecked = marked;
        selecteeEmployees.setList(state);

        break;
      case "SET_ROW":
        state[action.row].isChecked = !state[action.row].isChecked;
        break;

      default:
        return state;
    }
    return state;
  };
  const [sampleEmps, dispatch] = useReducer(reducer, emps);
  const item = data.item;

  useEffect(() => {
    setMarked(false);
    async function fetchData() {
      try {
        if (emps.length > 0) return;
        const res = await getEmployeesData();
        setEmps(res);
        dispatch({ type: "SET" });
      } catch (err) {
        // console.log(err);
      }
    }
    if (emps.length <= 0) fetchData();
    dispatch({ type: "SET" });
  }, [item.getText()]);

  const style = { color: "#6C757D" };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              <input
                type={"checkbox"}
                onChange={(event) => {
                  setMarked(event.target.checked);
                  dispatch({ type: "MARK_ALL" });
                }}
              />
            </StyledTableCell>
            <TableInnerTitles
              listOfNames={[
                "Image",
                "Name",
                "Department",
                "Phone",
                "Title",
                "Details",
              ]}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <Context.Provider value={sampleEmps}>
            {sampleEmps.map((row) => {
              return <SingleRow row={row} mark={marked} />;
            })}
          </Context.Provider>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

type ImageViewerData = {
  emp: Employee;
};
export const ImageViewer = (data: ImageViewerData) => {
  const [img, setImg] = useState("");
  const isChecked = CheckedInEmployees.getSingletonInstance().include(data.emp);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        setImg(await data.emp.getImage());
      } catch (e) {}
    };
    fetchImage();
  }, []);
  return (
    <img
      src={img}
      style={{
        width: 50,
        height: 50,
        borderRadius: 40,
        boxSizing: "border-box",
        border: isChecked ? "#008000 solid 0.3em" : "",
      }}
    />
  );
};

export default EmployeeListTable;

type SingleRowData = {
  row: Employee;
  mark: any;
};

const SingleRow = (data: SingleRowData) => {
  const emps = useContext(Context);
  let style = {
    fontFamily: "Exo2",
    color: "#6C757D",
    fontWeight: "500",
    fontSize: "13pt",
    padding: 2,
    margin: 0,
  };
  // const isCheckedIn = CheckedInEmployees.getSingletonInstance().include(data.row);
  data.row.isChecked = data.mark;
  const [checked, setCheck] = useState(data.mark);
  const ref = useRef(null);
  useEffect(() => {
    ref.current.checked = data.mark;
    setCheck(data.mark);
  }, [data.mark]);

  return (
    <StyledTableRow key={data.row.name} style={{ margin: 0 }}>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        <input
          type={"checkbox"}
          defaultChecked={checked}
          ref={ref}
          onChange={(event) => {
            data.row.isChecked = !data.row.isChecked;
            selecteeEmployees.setList(emps);
          }}
        />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" style={style}>
        <ImageViewer emp={data.row} />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" style={style} align="right">
        {data.row.name}
        {data.row.id}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="right" style={style}>
        {data.row.departmentName}
      </StyledTableCell>
      <StyledTableCell style={style} align="right">
        {data.row.phone}
      </StyledTableCell>
      <StyledTableCell style={style} align="right">
        {data.row.position}
      </StyledTableCell>
      <StyledTableCell style={style} align="right">
        <Link
          to={`${Links.dashboard}${Links.employeeList}/${data.row.id}`}
          state={{ employeeID: data.row.id }}
          style={{ color: "#60A7A3", paddingRight: "1.2em" }}
        >
          Check Employee Profile
        </Link>
      </StyledTableCell>
    </StyledTableRow>
  );
};
