import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Message } from "../../store/Models/Messages/Message";
import MessagesList from "./MessagesList";
import { Messages } from "../../store/Models/Messages/Messages";

type MessageRow = {
  message: Message<any>;
  open: boolean;
  setOpen: Function;
};

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  price: number
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}
type RowData = {
  message: Message<any>;
};
const Row = (data: RowData) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <MessageTitle message={data.message} open={open} setOpen={setOpen} />
      <MessageBody message={data.message} open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default function MessagesTable() {
  const [messages, setMessages] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setMessages(await Messages.getSingletonInstance().getAllMessages());
      console.log(messages);
    };
    fetchData();
  }, []);
  const style = {
    fontFamily: "Exo2_medium",
    fontSize: "14pt",
    fontWeight: "800",
    color: "#555555",
  };
  return (
    <TableContainer component={Paper} sx={{ width: "80%", marginTop: "2em" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={style}>Message ID</TableCell>
            <TableCell style={style}>To</TableCell>
            <TableCell style={style}>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((row: Message<any>) => (
            <Row message={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const MessageTitle = (data: MessageRow) => {
  //   const [open, setOpen] = React.useState(false);
    const style={
        fontFamily:"Exo2_medium",
        fontSize:"12pt",
    }
  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => data.setOpen(!data.open)}
        >
          {data.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell style={style} component="th" scope="row">
        {data.message.getID()}
      </TableCell>
      <TableCell style={style} >{data.message.getReceiver()}</TableCell>
      <TableCell style={style} >{data.message.getType()}</TableCell>
    </TableRow>
  );
};

const MessageBody = (data: MessageRow) => {
  return (
    <TableRow style={{
        backgroundColor:"#EEEEEE"
      }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={data.open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }} >
            <Typography variant="h6" gutterBottom component="div">
              Body
            </Typography>
            {data.message.getText()}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
