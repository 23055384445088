import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import {
  getTotalNumberOfEmployees,
  getTotalNumberOfAbsents,
  getInZoneNumber,
  getOutZoneNumber,
} from "../../../../services/getRequests";
import { firstDashboardData } from "../../../../store/Dashboards/FirstDashboard";
import TotalImg from "../../../../images/adminBoxes/circle.svg";
import AbsenceImg from "../../../../images/adminBoxes/absence.svg";
import InZoneImg from "../../../../images/adminBoxes/InZone.svg";
import OutZoneImg from "../../../../images/adminBoxes/OutZone.svg";


type AdminBoxSpanData = {
  name: string;
  number: number;
  decreaseFont?: boolean;
};

const AdminBoxSpan = (data: AdminBoxSpanData) => {
  // console.log(data);
  let style = {};
  if (data.decreaseFont) style = { fontSize: "13pt" };

  return (
    <Grid item textAlign={"center"}>
      <span className="titleSpan" style={style}>
        {data.name}
        <span className="numberSpan">{data.number}</span>
      </span>
    </Grid>
  );
};

export default AdminBoxSpan;

const getDataAndSet = async (api_get, set, index) => {
  const data = await api_get();
  if (data > 0) set(data);
  switch (index) {
    case 0:
      firstDashboardData.setTotalEmps(data);
      break;
    case 1:
      firstDashboardData.setAbsents(data);
      break;
    case 2:
      firstDashboardData.setInZones(data);
      break;
    case 3:
      firstDashboardData.setOutZones(data);
      break;
    default:
      break;
  }
};
export function TotalEmployeesComponent() {
  // console.log("Total Employees Component called!");
  const [empNumber, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getTotalNumberOfEmployees, setNumber, 0);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4} justifyContent={"center"}>
          <img
            src={TotalImg}
            className="iconStyle"
            style={{ width: "5em" }}
            alt="BRUJH"
          ></img>
          {/* <PeopleAltIcon className="iconStyle" style={getStyle("#A86F2B", "#D86F2B", 50, 50)} /> */}
        </Grid>
        <Grid item xs={8}>
        <AdminBoxSpan name="Total Employees" number={empNumber} /></Grid>
      </Grid>
    </Box>
  );
}

export function AbsenceEmployeeComponent() {
  const [absNumber, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getTotalNumberOfAbsents, setNumber, 1);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <img
            src={AbsenceImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid item xs={8}>
          <AdminBoxSpan name="Absence Employee Number" number={absNumber} />
        </Grid>
      </Grid>
    </Box>
  );
}

export function InZoneComponent() {
  const [inZones, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getInZoneNumber, setNumber, 2);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <img
            src={InZoneImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid item xs={8}>
          <AdminBoxSpan name="In Zone" number={inZones} />
        </Grid>
      </Grid>
    </Box>
  );
}

export function OutZoneComponent() {
  const [outZones, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getOutZoneNumber, setNumber, 3);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <img
            src={OutZoneImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid
          item
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          xs={8}
        >
          <AdminBoxSpan name="OutZone" number={outZones} />
        </Grid>
      </Grid>
    </Box>
  );
}
