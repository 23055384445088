import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Links } from "../../constants/links";
import { Link, useNavigate } from "react-router-dom";
import { CreateRouters } from "../../../routes";
import { Grid, Box } from "@mui/material";
import Title from "../../components/title/Title";
import AdminImg from "../../images/createAdmin.png";
import DepImg from "../../images/createDep.png";
import EmpImg from "../../images/createEmp.png";

import { margin } from "@mui/system";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";

// const CreateNew = () => {
//   return (
//     <div role="presentation">
//       <Breadcrumbs aria-label="breadcrumb">
//         <Link to={`${Links.dashboard}${Links.createNew}/`}>Admin</Link>
//         <Link to={`${Links.dashboard}${Links.createNew}/dep`}>Department</Link>
//         <Link to={`${Links.dashboard}${Links.createNew}/emp`}>Employee</Link>
//       </Breadcrumbs>
//     </div>
//   );
// };

const CreateBox = ({ createImage, buttonName, link = "" }) => {
  return (
    <Grid
      item
      xs={8}
      md={6}
      lg={3}
      style={{ border: "#aaaaaa solid 1px", borderRadius: 5,  margin:10 }}
    >
      <img
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "70%",
          height:"80%",
          display: "block",
          marginBottom: "1em",
        }}
        src={createImage}
        alt="imageBruh"
      />
      <Link to={link}>
      <button
        style={{
          position:"relative",
          width: "100%",
          backgroundColor: "#60A7A3",
          color: "white",
          fontFamily: "sans-serif",
          borderColor: "white",
          height: "20%",
          // right:10
        }}
      >
        {buttonName}
      </button>
      </Link>
    </Grid>
  );
};

const CreationBoxes = () => {
  return (
    <Box>
      <Grid container >
        <CreateBox createImage={AdminImg} buttonName="Create Admin" link={`${Links.dashboard}${Links.createNew}/admin`}/>
        <CreateBox createImage={DepImg} buttonName="Create Department"link={`${Links.dashboard}${Links.createNew}/dep`}/>
        <CreateBox createImage={EmpImg} buttonName="Create Employee"link={`${Links.dashboard}${Links.createNew}/emp`}/>
      </Grid>
    </Box>
  );
};
const CreateNew = () => {
  const nav= useNavigate();
  useEffect(()=>{
    if(!isTokenDefined()){
      nav(NOT_FOUND_URL);
    }
  },[])
  return (
    <Box>
      <Grid container direction="column" spacing={5}>
        <Grid item>
          <Title titleName="Create New" />
        </Grid>
        <Grid item>
          <CreationBoxes />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateNew;
