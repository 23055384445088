import axios_api from "../../services/api";
import { adminData } from "../store";
import { Indexable, ModelAPI, Selector } from "./api";
import Profile from "../../images/profile.png";
import { Departments } from "./Department";
import { MonthlyStatus } from "./MonthlyStatus";
import { CheckedInEmployees } from "./CheckedInEmployees";

type Data = {
  data: Number[];
};
export class Employee implements ModelAPI, Indexable {
  id: number;
  name: string;
  position: string;
  email: string;
  phone: string;
  password: string;
  salary: number;
  departmentName: string;
  isChecked: boolean = false;
  departmentID: number;
  private currentMonthlyStatus: MonthlyStatus;
  private photo: string;

  // constructor(){}
  constructor(
    id = 0,
    name = null,
    email = null,
    phone = null,
    password = null,
    departmentName = null,
    position = null
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.password = password;
    this.departmentName = departmentName;
    this.position = position;
    this.photo = null;
  }
  getID(): number {
    return this.id;
  }
  getName(): string {
    return this.name;
  }

  setMonthlyStatus(monthStatus: MonthlyStatus) {
    this.currentMonthlyStatus = monthStatus;
  }
  getMonthlyStatus = (): MonthlyStatus => this.currentMonthlyStatus;

  async transferSalary(){
    await this.currentMonthlyStatus.transferSalary(this);
  }

  computeSalary(){
    return this.currentMonthlyStatus.computeSalary(this.salary);
  }
  async getMonthlyInzones() {
    const data = await axios_api.get<Data>("/calcInEmp/" + this.id, {
      headers: {
        Authorization: `Bearer ${adminData.getToken()}`,
      },
      //   Accept:"application/json"
    });
    return data.data;
  }

  async getMonthlyOutzones() {
    const data = await axios_api.get<Data>("/calcOutEmp/" + this.id, {
      headers: {
        Authorization: `Bearer ${adminData.getToken()}`,
      },
      //   Accept:"application/json"
    });
    return data.data;
  }

  public async uploadImage(image) {
    var FormData = require("form-data");
    // console.log(id);
    // console.log(image);
    let data = new FormData();
    data.append("image", image);
    try {
      await axios_api
        .post(
          `storeimage/${this.id}`,
          { image: image },
          {
            data: data,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          this.setPhoto();
        });
    } catch (e) {
      console.log(e);
    }
  }
  public async getImage() {
    if (this.photo === null) await this.setPhoto();
    return this.photo;
  }

  async create() {
    let object = {
      company_id: `${adminData.getCompanyId()}`,
      department_id: this.departmentID,
      name: this.name,
      email: this.email,
      password: this.password,
      phone: `${this.phone}`,
      position: this.position,
      salary: `${this.salary}`,
      weekend_id: null,
    };

    // let data = {
    //   headers: {
    //     Authorization: `Bearer ${adminData.getToken()}`,
    //     //   Accept:"application/json"
    //   },
    //   data: object,
    // };

    // console.log(object);
    await axios_api.post("/register", object);
    Employees.getSingletonEmployees().resetEmployees();
  }
  async delete() {
    await axios_api.delete("/employees/" + this.id);
    Employees.getSingletonEmployees().resetEmployees();
  }
  async update() {
    let object = {
      company_id: `${adminData.getCompanyId()}`,
      department_id: this.departmentID,
      name: this.name,
      email: this.email,
      phone: `${this.phone}`,
      position: this.position,
      salary: `${this.salary}`,
      weekend_id: 0,
      dep_name: Departments.getDepartmentSingleton().getByID(this.departmentID)
        .name,
    };
    if (this.password.length !== 0)
      object = Object.assign(object, { password: this.password });
    // console.log(object);
    await axios_api.put("/employees/" + this.id, object);
    Employees.getSingletonEmployees().resetEmployees();
  }

  private async setPhoto() {
    try {
      const { data } = await axios_api.get("/getimage/" + this.id, {
        headers: { Authorization: `Bearer ${adminData.getToken()}` },
        responseType: "blob",
      });
      let objectURL = URL.createObjectURL(data);
      this.photo = objectURL;
    } catch (e) {
      // console.log(`${this.name} doesn't have a photo!`);
      this.photo = Profile;
    }
  }
}
export class Employees implements Selector<Employee> {
  private static instance: Employees = null;
  private employees: Employee[];

  private constructor() {
    this.employees = null;
  }
  public async getEmployees() {
    if (this.employees === null) await this.requestForEmployees();

    return this.employees;
  }
  public getByID(id: number): Employee {
    return this.employees.filter((emp) => emp.id === id)[0];
  }
  public resetEmployees() {
    this.employees = null;
  }
  private async requestForEmployees() {
    let data = await Employees.getEmployeesPayroll();
    this.employees = [];
    for (let e of data) {
      let emp = new Employee(
        e.employee_id,
        e.name,
        e.email,
        e.phone,
        e.password,
        e.dep_name,
        e.position
      );
      emp.salary = e.salary;
      emp.departmentID = e.department_id;
      let arrival = Number.parseInt(e.const_Arrival_time);
      let leave = Number.parseInt(e.const_Leave_time);
      leave = leave > arrival ? leave : leave + 24;
      let monthStatus = new MonthlyStatus(
        e.paid,
        leave - arrival,
        e.countabsence,
        e.totalwork,
        e.actualwork,
        e.delay,
        e.overTime,
        e.countattend
      );
      emp.setMonthlyStatus(monthStatus);
      this.employees.push(emp);
    }
    // console.log(this.employees);
    this.employees.sort((a, b) => {
      return a.departmentID - b.departmentID;
    });
  }
  public filterByCheckedIn():Employee[]{
    return this.employees.sort((a,b) =>{
      const incA=CheckedInEmployees.getSingletonInstance().include(a)? 1: 0;
      const incB=CheckedInEmployees.getSingletonInstance().include(b)? 1:0;
      return incB-incA;
    })

  }

  public static getSingletonEmployees() {
    // console.log("Employeees Singleton Called!");
    if (Employees.instance === null) Employees.instance = new Employees();
    return Employees.instance;
  }

  private static getEmployeesPayroll = async () => {
    const { data } = await axios_api.get(
      "/payroll/" + adminData.getCompanyId(),
      {
        headers: { Authorization: `Bearer ${adminData.getToken()}` },
      }
    );
    return data;
  };
}
