import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ResponsiveDrawer from "../../components/navbar/NavigationBar";
import { DashRouters } from "../../../routes";
import HolidayData from "../employees/HolidayData";
import "./css/dashboard.css";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";
import { useNavigate } from "react-router";

export const DashboardContext = React.createContext(null);


export default function Dashboard() {
  const [val, setVal] = React.useState(0);
  const [display, setDisplay] = React.useState("none");
  React.useEffect(() => {
    console.log(display);
    
  }, [display]);
  // console.log(adminData.getName())
  return (
    <>
      <DashboardContext.Provider value={{ setDisplay }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} rowSpacing={5}>
            <Grid item xs={1} sm={2}>
              <span onClick={() => setVal(val + 1)}>
                <ResponsiveDrawer key="dash_Nav" />
              </span>
            </Grid>
            <Grid item xs={12} xl={12-2}>
              <DashRouters />
            </Grid>
          </Grid>
        </Box>
        <div className="holiday-fixed-div" style={{ display: display }}>
          <HolidayData />
        </div>
      </DashboardContext.Provider>
    </>
  );
}
