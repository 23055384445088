import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./app/pages/signIn/SignIn";
import { Links } from "./app/constants/links";
import Dashboard from "./app/pages/dashboard/dashboard";
import DashboardData from "./app/pages/dashboard/Data";
import EmployeeList from "./app/pages/employees/EmployeeList";
import SingleEmployee from "./app/pages/employees/singleEmployee/SingleEmployee";
import DepartmentList from "./app/pages/departments/DepartmentList";
import SendMessage from "./app/pages/message/SendMessage";
import EmployeeMap from "./app/pages/Map/EmployeeMap";
import CreateNew from "./app/pages/createNew/CreateNew";
import CreateAdmin from "./app/pages/createNew/CreateAdmin";
import CreateDepartment from "./app/pages/createNew/CreateDepartment";
import CreateEmployee from "./app/pages/createNew/CreateEmployee";
import Payroll from "./app/pages/payroll/Payroll";
import PayrollSingleEmployee from "./app/pages/payroll/SingleEmployee/PayrollSingleEmployee";
import MessagesList from "./app/pages/MessagesList/MessagesList";
const Routers = () => {
  return (
    <Routes>
      <Route path={Links.home} element={<SignIn />} />
      <Route path={`${Links.dashboard}/*`} element={<Dashboard />} />
    </Routes>
  );
};

export const DashRouters = () => {
  return (
    <Routes>
      <Route path={"/"} element={<DashboardData />} />
      <Route path={Links.map} element={<EmployeeMap />} />
      <Route path={Links.employeeList} element={<EmployeeList />} />
      <Route
        path={`${Links.employeeList}${Links.employeeData}`}
        element={<SingleEmployee />}
      />
      <Route path={`${Links.departmentList}`} element={<DepartmentList />} />
      <Route path={`${Links.sendMessage}`} element={<SendMessage />} />
      <Route path={`${Links.messagesList}`} element={<MessagesList />} />
      <Route path={`${Links.createNew}/*`} element={<CreateNew />} />
      <Route path={`${Links.payroll}`} element={<Payroll/>} />
      <Route
        path={`${Links.payroll}${Links.employeeData}`}
        element={<PayrollSingleEmployee/>}
      />
      
      <Route path={`${Links.createNew}/admin`} element={<CreateAdmin />} />
      <Route path={`${Links.createNew}/dep`} element={<CreateDepartment />} />
      <Route path={`${Links.createNew}/emp`}element={<CreateEmployee />} />

    </Routes>
  );
};

export const CreateRouters = () => {
  return <Routes></Routes>;
};
export default Routers;
