import { Grid } from "@mui/material";
import React, { Context, useContext, useEffect, useState } from "react";
import { Employee } from "../../../store/Models/Employee";
import { ContextProps } from "./SingleEmployee";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import { MultipleMonthlyStatus } from "../../../store/Models/MonthlyStatus";
import { Stats } from "fs";

const CONSTANTS = {
  IN_ZONES: "In Zone",
  OUT_ZONES: "Out Zone",
  ABSENCE: "Absence Days",
  PRESENT: "Present Days",
};

type EmployeeChartsData = {
  context: Context<ContextProps>;
};

type ChartData = {
  employee: Employee;
  status: MultipleMonthlyStatus;
  name1: string;
  name2: string;
};

export const getRequiredArray = async (
  emp: Employee,
  status: MultipleMonthlyStatus,
  value
) => {
  let data = undefined;
  if (value === CONSTANTS.IN_ZONES) data = await emp.getMonthlyInzones();
  else if (value === CONSTANTS.OUT_ZONES) data = await emp.getMonthlyOutzones();
  else {
    await status.getMonthlyStatus(); //Used to load the data first if not loaded
    if (value === CONSTANTS.ABSENCE) data = await status.getTotalAbsent();
    else data = await status.getTotalAttendance();
  }
  return data as Number[];
};

const EmployeeCharts = (data: EmployeeChartsData) => {
  const employee = useContext(data.context).employee;
  const status = new MultipleMonthlyStatus(employee);
  return (
    <Grid container>
      <Grid item xs={0} xl={1}></Grid>
      <Grid item xs={8} xl={5}>
        <FirstChart
          name1={CONSTANTS.IN_ZONES}
          name2={CONSTANTS.OUT_ZONES}
          employee={employee}
          status={status}
        />
      </Grid>
      <Grid item xs={8} xl={5}>
        <FirstChart
          name1={CONSTANTS.ABSENCE}
          name2={CONSTANTS.PRESENT}
          employee={employee}
          status={status}
        />
      </Grid>
    </Grid>
  );
};

const FirstChart = (chartData: ChartData) => {
  const [data, setData] = useState(
    updateData([], [], chartData.name1, chartData.name2)
  );
  useEffect(() => {
    const fetchData = async () => {
      const data1 = await getRequiredArray(
        chartData.employee,
        chartData.status,
        chartData.name1
      );
      const data2 = await getRequiredArray(
        chartData.employee,
        chartData.status,
        chartData.name2
      );
      const rec = updateData(data1, data2, chartData.name1, chartData.name2);
      setData(rec);
    };
    fetchData();
  }, []);

  return <Line data={data} />;
};

const updateData = (
  arr1: Number[],
  arr2: Number[],
  name1: string,
  name2: string
) => {
  return {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: name1,
        data: arr1,
        // backgroundColor: "#0000ff",
        // fill: false,
        borderColor: "#60A7A3",
        tension: 0.5,
      },
      {
        label: name2,
        data: arr2,
        // backgroundColor: "#0000ff",
        // fill: false,
        borderColor: "#D86F2B",
        tension: 0.5,
      },
    ],
    options: {
      maintainAspectRatio: true,
    },
  };
};

export default EmployeeCharts;
