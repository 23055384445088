import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import SignInForm from "./SignInForm";
import "./css/signIn.css";
import MetaTrackerImg from "../../images/metaTracker.jpg";
import BuildingImg from "../../images/signInBuilding.svg";

const SignIn = () => {
  return (
    <div>
      <div className="sign-div" id="sign_form_div">
        <div style={{ textAlign: "center" }}>
          <img
            src={MetaTrackerImg}
            alt="Meta Tracker"
            width="330px"
            id="tracker-img"
          />
        </div>

        <LeftPart />
      </div>
      <RightPart />
    </div>
  );
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LeftPart = () => {

  return (
    <>
      <div id="sign-and-welcome-div">
        <p>
          <span
            style={{
              fontFamily: "Exo2_bold",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: "32px",
              lineHeight: "38px",
              color: "#494C50",
              paddingBottom:10
            }}
          >
            Welcome to the Admin Tracking System
          </span>
          <br />
          <span style={{
            fontFamily: "Exo 2",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "32px",
            lineHeight: "38px",
            color: "#494C50",

          }}> Sign in to your admin panel</span>
        </p>
        <SignInForm />
      </div>
    </>
  );
};

const RightPart = () => {
  return (
    <div className="sign-div" id="img_div" style={{ paddingBottom: 20 }}>
      <img src={MetaTrackerImg} alt="Meta Tracker" width="47%" height="20%" />
      <img
        src={BuildingImg}
        alt="Building"
        // width="88%"
        style={{height:"35em", width:"88%"}}
      />
    </div>
  );
};

export default SignIn;
