import { Box, Grid, Input } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SelectIndex } from "../message/SelectMessageType";
import { createMultipleEmployees } from "../../services/library";
import { Links } from "../../constants/links";
import Title from "../../components/title/Title";
import { Button } from "reactstrap";
import "./index.css";
import { Employee, Employees } from "../../store/Models/Employee";
import { Department, Departments } from "../../store/Models/Department";
import { useNavigate } from "react-router";
const CreateEmployee = () => {
  return (
    <div>
      <Title
        backLink={`${Links.dashboard}${Links.createNew}`}
        titleName="Create Employee"
      />
      <CreateMultipleEmployees />
      <CreateSingleEmployee />
    </div>
  );
};

export default CreateEmployee;

function CreateMultipleEmployees() {
  const navigate= useNavigate();
  const [file, setFile] = useState();
  const [fileLabel, setFileLabel] = useState("No File Chosen");

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileLabel(e.target.files[0].name)
    
  };

  const handleOnSubmit = (e) => {
    if (file) {
      fileReader.onload = async function (event) {
        const csvOutput = event.target.result;
        try {
          await createMultipleEmployees(csvOutput);
          alert('DONE!');
          navigate(`${Links.dashboard}${Links.createNew}`)
        } catch (E) {
          console.log(E);
          alert("Error");
        }
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div
      style={{ textAlign: "center", marginTop: 30 }}
      id="csv-box"
      className="emp-form-boxes"
    >
      <p>Import employees form excel up to 1000 employees</p>
      <form style={{ width: "100%" }}>
        <div style={{ display: "block", width: "100%" }}>
          <div
            style={{
              textAlign: "left",
              width: "60%",
              display: "inline-block",
            }}
          >
            {fileLabel}
          </div>
          <div style={{ width: "30%", display: "inline-block" }}>
            <label id={"csvFileInput"}>
              Choose file
              <input
                type={"file"}
                accept={".csv"}
                style={{ display: "none" }}
                onChange={handleOnChange}
              />
            </label>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        ></div>
      </form>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          color: "#D86F2B",
          textDecoration: "underline",
          fontSize: "16px",
        }}
        onClick={(e) => {
          handleOnSubmit(e);
        }}
      >
        Read a File
      </button>
    </div>
  );
}

function CreateSingleEmployee() {
  const navigate= useNavigate();
  let [departments, setDepartments] = useState([new Department()]);
  let currentId = 0;

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const positionRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const salaryRef = useRef(null);

  useEffect(() => {
    const fetchDeps = async () => {
      setDepartments(
        await Departments.getDepartmentSingleton().getDepartments()
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentId = departments[0].id;
    };
    fetchDeps();
  }, []);
  const createEmp = async (e) => {
    e.preventDefault();
    try {
      const name = nameRef.current.value;
      const phone = parseInt(phoneRef.current.value);
      const position = positionRef.current.value;
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      const salary = salaryRef.current.value;
      if (name === "" || position === "" || email === "")
        throw new Error("Name or Position are empty!");
      const employee = new Employee(
        -1,
        name,
        email,
        phone,
        password,
        "",
        position
      );
      employee.salary = salary;
      employee.departmentID = currentId;
      try{
      await employee.create();
      Employees.getSingletonEmployees().resetEmployees();
      navigate(`${Links.dashboard}${Links.createNew}`)
      }catch(E){
        alert('Error Occured!');
        console.log(E);
      }

    } catch (e) {
      // alert("ERROR");
      console.log(e);
    }
    return false;
  };

  return (
    <div className="emp-form-boxes" style={{ marginRight: 20 }}>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={8} md={5}>
            <input ref={nameRef} className="text-input" placeholder="Name" />
          </Grid>
          <Grid item xs={8} md={5}>
            <input
              ref={positionRef}
              className="text-input"
              placeholder="Position"
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <input ref={emailRef} className="text-input" placeholder="Email" />
          </Grid>
          <Grid item xs={8} md={5}>
            <input
              ref={passwordRef}
              className="text-input"
              placeholder="Password"
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <input ref={phoneRef} className="text-input" placeholder="Phone" />
          </Grid>
          <Grid item xs={8} md={5} textAlign="center">
            {departments.length > 0 && (
              <SelectIndex
                data={departments}
                name="Departments"
                setter={(id) => {
                  currentId = id;
                }}
              />
            )}
          </Grid>
          <Grid item xs={8} md={5}>
            <input
              ref={salaryRef}
              className="text-input"
              placeholder="Salary"
              type="number"
            />
          </Grid>

          <Grid item xs={12} md={7} textAlign="end">
            <button
              style={{
                position: "relative",
                top: "50%",
                backgroundColor: "#60A7A3",
                color: "white",
                padding: "0.75em 1.5em 0.75em 1.75em",
                border: "none",
              }}
              onClick={createEmp}
            >
              Add Employee
            </button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
