import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { PIE_LABELS, UserData } from "../../../../constants/data";
import Chart from "chart.js/auto";
import { Box, Grid } from "@mui/material";
import "../../css/adminBox.css";
import "../../css/charts.css";
import { observer } from "mobx-react-lite";
import {ArcElement} from 'chart.js'
import { FirstDashboard } from "../../../../store/Dashboards/FirstDashboard";
Chart.register(ArcElement);

const colors = {
  ABSENT: "#817F78",
  OUT_ZONE: "#D86F2B",
  IN_ZONE: "#60A7A3",
};

type PieData= {
  dashboardData: FirstDashboard
};

const PieChart = observer((data:PieData) => {
  // console.log(dashboardData.getAbsents())

  const [dat, setDat] = useState({
    labels: [PIE_LABELS.IN_ZONE, PIE_LABELS.OUT_ZONE, PIE_LABELS.ABSENT],
    datasets: [
      {
        label: "Daily Company Statistics",
        data: [
          data.dashboardData.getInZones() + 55,
          data.dashboardData.getOutZones() + 22,
          data.dashboardData.getAbsents(),
        ],
        backgroundColor: [colors.IN_ZONE, colors.OUT_ZONE, colors.ABSENT],
      },
    ],
    options: {
      padding: 0,
    },
  });
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        // left: 100,
        // right: 100,
        bottom:0,
        top:0
      },
    },
    // scaleShowValues: true,
    radius: 80,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box className="adminBox" style={{ padding: 5, paddingBottom:0, height: "100%" }}>
      <h4 id="chart-label">Daily Company Statistics</h4>
      <Grid container spacing={10}>
        <Grid item md={4} xs={12}>
          <ul id="pieLabels">
            <li style={{ color: colors.IN_ZONE }}>
              <span>{PIE_LABELS.IN_ZONE}</span>
            </li>
            <li style={{ color: colors.OUT_ZONE }}>
              <span>{PIE_LABELS.OUT_ZONE}</span>
            </li>
            <li style={{ color: colors.ABSENT }}>
              <span>{PIE_LABELS.ABSENT}</span>
            </li>
          </ul>
        </Grid>
        <Grid item>
          <Pie
            data={dat}
            options={options}
            style={{position:"relative", bottom:"10%"}}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default PieChart;
