import { useEffect, useState } from "react";
import { isDefined } from "../services/library";
import { Employee } from "../store/Models/Employee";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

type ImageViewerData = {
  employee: Employee;
  isUpload?: boolean;
};

const ImageViewer = (data: ImageViewerData) => {
  // let employee:Employee= data as Employee;
  // console.log(employee);
  const isUpload = !isDefined(data.isUpload);

  const [img, setImg] = useState("");
  useEffect(() => {
    const fetchImage = async () => {
      try {
        setImg(await data.employee.getImage());
      } catch (e) {
        console.log(e);
        console.log("Pic not loaded");
      }
    };
    fetchImage();
  }, []);

  const sendFile = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    if (file) {
      fileReader.onload = function (event) {
        let imgOutput = event.target.result;
        data.employee.uploadImage(file);
      };
    }
  };
  return (
    <div style={{ width: "173px",height:"173px" }}>
      <img
        src={img}
        style={{
          width: "173px",
          height: "173px",
          borderRadius: 10,
          padding: 0,
        }}
        alt="ss"
      />
      {isUpload && (
        <button
          style={{
            position: "relative",
            bottom: "40px",
            background: "none",
            border: "none",
            color: "white",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          <CameraAltIcon fontSize="small" />
          <label>
            <span style={{ position: "relative", bottom: "0.3em" }}>
              Change Photo
              <input
                type={"file"}
                id={"imgFileInput"}
                accept={"image/png, image/jpeg"}
                style={{ display: "none" }}
                onChange={sendFile}
              />
            </span>
          </label>
        </button>
      )}
    </div>
  );
};

export default ImageViewer;
