import { observer } from "mobx-react-lite";
import React, { createContext, useEffect, useState } from "react";
import Title from "../../components/title/Title";
import { searchItem } from "../../store/store";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { TableInnerTitles } from "../../components/TableTitle";
import { TableHead } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { isDefined, isTokenDefined, NOT_FOUND_URL } from "../../services/library";

import { Employee, Employees } from "../../store/Models/Employee";
import ListEmployee from "./ListEmployee";
import { SearchItem } from "../../store/SearchItem";
import { useNavigate } from "react-router";

const Payroll = () => {
  const [data, setData] = useState([]);
  const navigate= useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setData(await Employees.getSingletonEmployees().getEmployees());
    };
    if(!isTokenDefined())
      navigate(NOT_FOUND_URL);
    fetchData();
  }, []);
  return (
    <div>
      <Title titleName={"Payroll"} />
      <PayrollTable searchItem={searchItem} employees={data} />
    </div>
  );
};

export default Payroll;

type PayrollTableData = {
  searchItem: SearchItem;
  employees: Employee[];
};
const PayrollTable = observer((data: PayrollTableData) => {
  // const { employees }= useContext(PayrollContext);
  useEffect(() => {
  }, [data.employees]);
  return (
    <TableContainer component={Paper} sx={{width:"80%", marginTop:"1em"}}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableInnerTitles
              listOfNames={[
                "Image",
                "Name",
                "Department",
                "Total Working Hours",
                "Actual Working Hours",
                "Details",
              ]}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {isDefined(data.employees) &&
            data.employees
              .filter((elem: Employee) => {
                const text = data.searchItem.getText().toLowerCase();
                return (
                  elem.name.toLowerCase().includes(text) ||
                  elem.departmentName.toLowerCase().includes(text) ||
                  elem.getMonthlyStatus().getTotalWorkingHours().toString().includes(text) ||
                  elem.getMonthlyStatus().getActualWorkingHours().toString().includes(text)
                );
              })
              .map(ListEmployee)}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
