import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { Context, useContext, useEffect, useState } from "react";
import { isDefined } from "../../../services/library";
import { Department, Departments } from "../../../store/Models/Department";
import {
  CheckedInEmployee,
} from "../../../store/Models/EmployeeLocation";
import { DepartmentMarker } from "../../Map/map";
import { ContextProps } from "./SingleEmployee";

const API_KEY = "AIzaSyDpGaGpj9uoLbfhxGzXru_25FkoOjsl_mI";

type TrackerData = {
  context: Context<ContextProps>;
};
const EmployeeTracker = (data: TrackerData) => {
  const context = useContext(data.context);

  return (
    <div
      style={{
        position: "absolute",
        width: "60%",
        // right:"20%",
        display: context.displayMap ? "block" : "none",
        backgroundColor: "rgb(255,255,255)",
        height: "90%",
        border: "black solid 1px",
      }}
    >
      <div>
        <h2 style={{ display: "inline", position: "relative", left: "30%" }}>
          Track Employee{" "}
          <span style={{ color: "#60A7A3", textDecoration: "underline" }}>
            {context.employee.name}
          </span>
        </h2>
        <button
          style={{
            position: "relative",
            float: "right",
          }}
          onClick={(event) => {
            context.setter(false);
          }}
        >
          X
        </button>
      </div>
      <div
        style={{
          width: "90%",
          height: "100%",
          position: "relative",
          left: "5%",
        }}
      >
        <TrackEmployee context={data.context} />
      </div>
    </div>
  );
};

const containerStyle = {
  width: "100%",
  height: "90%",
};
const TrackEmployee = (data: TrackerData) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });
  const context = useContext(data.context);
  const checkedEmployee = new CheckedInEmployee(-1, context.employee);
  const setMap = useState(null)[1];
  const [center, setCenter] = useState({ lat: 105, lng: 100 });
  const [department, setDepartment] = useState(new Department());
  const [empLocation, setEmpLocation] = useState({ lat: 0, lng: 0 });

  const updateLatLng = async() => {
    checkedEmployee.updateLatAndLng().then(res=>{
      console.log("CALLED!");
      setEmpLocation({
        lat: checkedEmployee.getLat(),
        lng: checkedEmployee.getLng(),
      });
      console.log(empLocation);
    });
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      await Departments.getDepartmentSingleton().getDepartments();
      setDepartment(
        Departments.getDepartmentSingleton().getByID(
          checkedEmployee.getEmployee().departmentID
        )
      );
    };
    fetchDepartment();
    setCenter({
      lat: department.lat,
      lng: department.lng,
    });
    updateLatLng();
    const interval = setInterval(updateLatLng, 10000);
    return () => clearInterval(interval);
  }, [department]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  if (!context.displayMap) return <></>;

  // setInterval(updateLatLng, 1000);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {isDefined(department.name) && (
        <DepartmentMarker department={department} />
      )}
      <Marker position={empLocation} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default EmployeeTracker;
