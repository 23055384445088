import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Title from "../../components/title/Title";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { FormGroup, Input } from "@mui/material";

import MessageBreadCrumb, { SelectIndex, VALUES } from "./SelectMessageType";

import { useNavigate } from "react-router";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";
import { Department, Departments } from "../../store/Models/Department";
import {
  AnnouncementMessage,
  DepartmentMessage,
  PrivateMessage,
} from "../../store/Models/Messages/Message";
import { Employee, Employees } from "../../store/Models/Employee";
import { Links } from "../../constants/links";

const AnnouncementMessageComponent = () => {
  const navigate = useNavigate();
  const sendAnnouncementMessage = async (title, body) => {
    const message = new AnnouncementMessage(title + "\n\n" + body);
    await message.sendMessage();
  };
  return (
    <div>
      <h2>{VALUES[0]}</h2>
      <SendMessageForm caller={sendAnnouncementMessage} />
    </div>
  );
};
const DepartmentMessageComponent = () => {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([new Department()]);
  let currentId = 0;
  useEffect(() => {
    const fetchDeps = async () => {
      setDepartments(
        await Departments.getDepartmentSingleton().getDepartments()
      );
    };
    fetchDeps();
  }, []);

  const sendDepartmentMessage = async (title, body) => {
    const department = Departments.getDepartmentSingleton().getByID(currentId);
    const message = new DepartmentMessage(department, title + "\n\n" + body);
    await message.sendMessage();
  };

  return (
    <div>
      <h2>{VALUES[1]}</h2>
      {departments.length > 0 && (
        <div style={{ margin: "3em 0 3em 0" }}>
          <SelectIndex
            data={departments}
            name="To Department"
            setter={(id) => {
              currentId = id;
            }}
          />
        </div>
      )}
      <SendMessageForm caller={sendDepartmentMessage} />
    </div>
  );
};

const EmployeeMessageComponent = () => {
  let [employees, setEmployees] = useState([new Employee()]);
  let currentId = 0;

  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployees(await Employees.getSingletonEmployees().getEmployees());
    };
    fetchEmployees();
  }, []);

  const sendEmployeeMessage = async (title, body) => {
    const employee = Employees.getSingletonEmployees().getByID(currentId);
    const message = new PrivateMessage(employee, title + "\n\n" + body);
    await message.sendMessage();
  };

  return (
    <div>
      <h2>{VALUES[2]}</h2>
      {employees.length > 0 && (
        <div style={{ margin: "3em 0 3em 0" }}>
          <SelectIndex
            data={employees}
            name="To Employee"
            setter={(id) => {
              currentId = id;
            }}
          />
        </div>
      )}
      <SendMessageForm caller={sendEmployeeMessage} />
    </div>
  );
};

const SendMessage = () => {
  const [breadcrumb, setBreadcrumb] = useState(0);
  const nav = useNavigate();
  useEffect(() => {
    if (!isTokenDefined()) nav(NOT_FOUND_URL);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={5} columns={12}>
        <Grid item marginBottom={3}>
          <Title titleName="Send Message" />
        </Grid>
        <Grid item>
          <MessageBreadCrumb setter={setBreadcrumb} />
        </Grid>
        <Grid item>
          {" "}
          {breadcrumb === 0 && <AnnouncementMessageComponent />}
          {breadcrumb === 1 && <DepartmentMessageComponent />}
          {breadcrumb === 2 && <EmployeeMessageComponent />}
        </Grid>
      </Grid>
    </Box>
  );
};

const SendMessageForm = ({ caller }) => {
  const titleRef = useRef(null);
  const bodyRef = useRef(null);

  const sendMessage = async (event) => {
    let title = titleRef.current.value;
    let body = bodyRef.current.value;
    let message = "";
    try {
      if (title.trim().length === 0 || body.trim().length === 0)
        throw new Error("Title and body must not be empty");
      await caller(title, body);
      titleRef.current.value='';
      bodyRef.current.value='';
    } catch (e) {
      console.log(e);
      alert("ERROR");
    }
  };

  return (
    <FormGroup
      style={{
        marginRight: "3em",
      }}
    >
      <Input
        inputRef={titleRef}
        id="msg-title-input"
        aria-describedby="my-helper-text"
        style={{
          marginBottom: 20,
          border: "1px solid #2E3133",
          borderColor: "#CCC",
          borderRadius: "5px",
          padding: 5,
          height: "3em",
        }}
        placeholder="Enter Title Here"
      />

      <TextareaAutosize
        ref={bodyRef}
        minRows={12}
        aria-label="maximum height"
        placeholder="Your Message Here"
        style={{
          border: "1px solid #2E3133",
          borderRadius: "5px",
          borderColor: "#CCC",
          padding: 10,
          marginBottom: 10,
          outline: "none",
        }}
      />
      <div style={{ textAlign: "center" }}>
        <button
          onClick={sendMessage}
          style={{
            width: "25%",
            backgroundColor: "#60A7A3",
            borderRadius: 5,
            border: "1px solid #60A7A3",

            color: "#FFFFFF",
            padding: 5,
            fontFamily: "Exo2_medium",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16pt",
            lineHeight: "19px",
          }}
        >
          Send Message
        </button>
      </div>
    </FormGroup>
  );
};
export default SendMessage;
