import { throws } from "assert";
import axios_api from "../../services/api";
import { isDefined } from "../../services/library";
import { adminData } from "../store";
import { Department, Departments } from "./Department";
import { Employee, Employees } from "./Employee";

export class CheckedInEmployee {
  private employee: Employee;
  private lat: number;
  private lng: number;
  private startTime: string;
  private endTime: string;

  constructor(employeeID: number = -1, employee: Employee = null) {
    if (employeeID !== -1) this.setEmployee(employeeID);
    else this.employee = employee;
  }
  private setEmployee(id: number) {
    this.employee = Employees.getSingletonEmployees().getByID(id);
    if (!isDefined(this.employee)) console.log("ERRORRR in ID " + id);
  }
  public async updateLatAndLng() {
    const { data } = await axios_api.get("latlngemp/" + this.employee.getID());
    // console.log(data);
    if (data.length === 0) return;
    console.log("NOW HERE");
    this.setLat(Number.parseFloat(data[0].lat));
    this.setLng(Number.parseFloat(data[0].lng));
    console.log(data[0]);
    console.log("Returned from update");
  }
  public setLat(lat: number) {
    this.lat = lat;
    console.log(this.lat);
  }
  public setLng(lng: number) {
    this.lng = lng;
  }
  public setStartTime(startTime: string) {
    this.startTime = startTime;
  }
  public setEndTime(endTime: string) {
    if (endTime === null) this.endTime = "Not Finished";
    else this.endTime = endTime;
  }
  public getStartTime = (): string => this.startTime;
  public getEndTime = (): string => this.endTime;
  public getLat = (): number => this.lat;
  public getLng = (): number => this.lng;
  public getEmployee = (): Employee => this.employee;

  public isInside(): boolean {
    const department = Departments.getDepartmentSingleton().getByID(
      this.employee.departmentID
    );

    let distance = this.computeDistance(department);
    console.log(
      `Distance is ${distance} between ${this.employee.name} of (${this.lat},${this.lng}) and department of (${department.lat},${department.lng})`
    );
    return distance < department.radius;
  }

  private computeDistance(dep: Department) {
    const R = 6371e3; // metres
    const φ1 = (dep.lat * Math.PI) / 180; // φ, λ in radians
    const φ2 = (this.lat * Math.PI) / 180;
    const Δφ = ((dep.lat - this.lat) * Math.PI) / 180;
    const Δλ = ((dep.lng - this.lng) * Math.PI) / 180;
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }
}
