import * as React from "react";
import { MonthlyStatus } from "../../../store/Models/MonthlyStatus";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BackArrow from "../../../images/backArrow.svg";
import NextArrow from "../../../images/nextArrow.svg";
import { getFixedValue } from "../../../services/library";

type StatsData = {
  qeuries: MonthlyStatus[];
  salary: number;
};

/**
 * We are sure that data is > 0
 */
export default function StatsTable(data: StatsData) {
  const [index, setIndex] = React.useState(data.qeuries.length - 1);
  return (
    <div style={{ marginTop: "2em" }}>
      <div>
        <div
          style={{
            float: "left",
          }}
        >
          <h1
            style={{
              fontFamily: "Exo2_medium",
              display: "inline",
              fontSize: "18pt",
            }}
          >
            Payroll History
          </h1>
        </div>
        <div
          style={{
            position: "relative",
            float: "right",
            display: "flex",
            alignItems: "center",
            bottom: "13px",
          }}
        >
          <h2
            style={{
              display: "inline",
              color: "#D86F2B",
              fontFamily: "Exo2_medium",
            }}
          >
            {data.qeuries[index].getMonth()} {data.qeuries[index].getYear()}
          </h2>
          <div
            style={{
              display: "inline",
            }}
          >
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => {
                if (index > 0) setIndex(index - 1);
              }}
            >
              <img src={BackArrow} alt="" />
            </button>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => {
                if (index < data.qeuries.length - 1) setIndex(index + 1);
              }}
            >
              <img src={NextArrow} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "block",
          width: "100%",
          float: "left",
          marginTop: "2em",
        }}
      >
        <SingleMonth monthlyData={data.qeuries[index]} salary={data.salary} />
      </div>
    </div>
  );
}

type MonthData = {
  monthlyData: MonthlyStatus;
  salary: number;
};
export const SingleMonth = (data: MonthData) => {
  const headStyle = { fontSize: "12pt", fontWeight: 800 };
  return (
    <div style={{ display: "flex" }}>
      <TableContainer component={Paper} sx={{ minWidth: "80%" }}>
        <Table sx={{ minWidth: "100%" }} style={{ paddingBottom: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell style={headStyle}>Total Working Hours</TableCell>
              <TableCell align="right" style={headStyle}>
                Paid Working Hours
              </TableCell>
              <TableCell align="right" style={headStyle}>
                Actual Working Hours
              </TableCell>
              <TableCell align="right" style={headStyle}>
                Over Time
              </TableCell>
              <TableCell align="right" style={headStyle}>
                Absent Hours
              </TableCell>
              <TableCell align="right" style={headStyle}>
                Delay Hours
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={
                data.monthlyData.getActualWorkingHours() +
                data.monthlyData.getOvertime()
              }
            >
              <TableCell component="th" scope="row">
                {data.monthlyData.getTotalWorkingHours()} hrs
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {getFixedValue(data.monthlyData.getNormalWorkingHours())} hrs
              </TableCell>
              <TableCell align="right">
                {getFixedValue(data.monthlyData.getActualWorkingHours())} hrs
              </TableCell>
              <TableCell align="right">
                {getFixedValue(data.monthlyData.getOvertime())} hrs
              </TableCell>
              <TableCell align="right">
                {getFixedValue(
                  data.monthlyData.getAbsentDays() *
                    data.monthlyData.getShiftHours()
                )}{" "}
                hrs
              </TableCell>
              <TableCell align="right">
                {getFixedValue(data.monthlyData.getDelay())} hrs
              </TableCell>
            </TableRow>
            <TableRow
              key={
                data.monthlyData.getActualWorkingHours() +
                data.monthlyData.getOvertime() +
                5
              }
            >
              <TableCell
                component="th"
                scope="row"
                style={{ color: "#60A7A3" }}
              >
                {getFixedValue(
                  data.monthlyData.getTotalWorkingHours() * data.salary
                )}
                $
              </TableCell>
              <TableCell align="right" style={{ color: "#60A7A3" }}>
                {getFixedValue(
                  data.monthlyData.getNormalWorkingHours() * data.salary
                )}
                $
              </TableCell>
              <TableCell align="right" style={{ color: "#60A7A3" }}>
                {getFixedValue(
                  data.monthlyData.getActualWorkingHours() * data.salary
                )}
                $
              </TableCell>
              <TableCell align="right" style={{ color: "#60A7A3" }}>
                {getFixedValue(
                  data.monthlyData.getOvertime() * data.salary * 1.5
                )}
                $
              </TableCell>
              <TableCell align="right" style={{ color: "#60A7A3" }}>
                -{" "}
                {getFixedValue(
                  data.monthlyData.getAbsentDays() *
                    data.monthlyData.getShiftHours() *
                    2 *
                    data.salary
                )}
                $
              </TableCell>
              <TableCell align="right" style={{ color: "#60A7A3" }}>
                -{getFixedValue(data.monthlyData.getDelay() * data.salary * 2)}$
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        sx={{ minWidth: "20%", marginLeft: "1em", paddingBottom: 0 }}
      >
        <Table style={{ paddingBottom: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "18pt",
                  fontWeight: 800,
                  padding: "2em 0em 1.2em 0em",
                }}
              >
                Total Salary
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: 0 }}>
            <TableRow sx={{ border: "none" }}>
              <TableCell
                align="center"
                style={{
                  paddingBottom: "2em",
                  border: "none",
                  fontSize: "14pt",
                  fontWeight: "600",
                  color: "#60A7A3",
                }}
              >
                {data.monthlyData.computeSalary(data.salary)}$
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
