import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../components/TableTitle";
import { getFixedValue, isDefined } from "../../services/library";
import { Employee } from "../../store/Models/Employee";
import { ImageViewer } from "../employees/EmployeeListTable";

const ListEmployee = (employee: Employee) => {
  const style:CSSProperties = { color: "#6C757D", textAlign: "end", fontSize:"12pt", fontWeight:"800", fontFamily:"Exo2"};
  // console.log(employee);
  return !isDefined(employee) ? (
    <></>
  ) : (
    <StyledTableRow key={`p_roll_${employee.id}`}>
      <StyledTableCell component="th" scope="row">
        <ImageViewer emp={employee} />
      </StyledTableCell>

      <StyledTableCell
        component="th"
        scope="row"
        style={style}
      >
        {employee.name}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        style={style}
      >
        {employee.departmentName}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        style={{...style, textAlign:"center"}}
      >
        {employee.getMonthlyStatus().getTotalWorkingHours()}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        style={{...style, textAlign:"center"}}
      >
        {getFixedValue(employee.getMonthlyStatus().getActualWorkingHours())}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        style={style}
      >
        <Link
          to={`${employee.id}`}
          state={{ id: employee.id }}
          style={{ color: "#D86F2B" }}
        >
          View Detailed Payroll
        </Link>
      </StyledTableCell>
    </StyledTableRow>
  );
};
export default ListEmployee;
