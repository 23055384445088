import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import DashBoardCss from "./title.module.css";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";
import { searchItem } from "../../store/store";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import Image from '../../images/back.png';

export const LeftPart = ({ titleName ,backLink}) => {
  useEffect(() => {
    searchItem.emptyText();
  }, []);

  return (
    <>
      <Grid item xs={12} md={3}>
        {backLink != null && <Link to={backLink}><img src={Image} alt="bb" style={{marginRight:"0.5em"}}/></Link>}
        <h1 id={DashBoardCss.h1_data}>{titleName}</h1>
      </Grid>
      <Grid item xs={8} md={4}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#F8F8F8",
            padding: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon style={{ display: "inline", color: "#CCC" }} />
          <input
            id={DashBoardCss.searchInput}
            type="text"
            placeholder="search.."
            onChange={(s) => {
              searchItem.setText(s.target.value);
            }}
          />
        </div>
      </Grid>
    </>
  );
};
const Title = ({ titleName, Button=null, backLink=null}) => {
  // console.log(button);
  return (
    <Grid container spacing={2} marginTop={0.01} alignItems="center">
      <LeftPart backLink={backLink} titleName={titleName} />
      <Grid item md={4} xs={0}>
        {Button!== null && <Button/> }
      </Grid>
      <Grid item xs={1}>
        <div style={{ textAlign: "right", marginRight: "3em" }}>
          <button id={DashBoardCss.searchButton}>
            <NotificationsNoneRoundedIcon style={{ color: "orange" }} />
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Title;
