import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Circle } from "@react-google-maps/api";
import { isDefined } from "../../services/library";
import { Department } from "../../store/Models/Department";

const API_KEY = "AIzaSyDkVOQ71uHQELj6k_gmsRMID6umLivk-PM";

const containerStyle = {
  width: "100%",
  height: "600px",
};



type MapData= {
  departments: Department[],
  emps
};

export const Map = (mapData:MapData) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });
  const setMap = useState(null)[1];
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (isDefined(mapData.departments) && isDefined(mapData.departments[0]))
      setCenter({
        lat: mapData.departments[0].lat,
        lng: mapData.departments[0].lng,
      });
  }, [mapData.departments]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  },[]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={mapData.departments.length > 1 ? 1 : 15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {mapData.departments.map((dep) => {
        if (typeof dep === "undefined") return <></>;
        return (
          <DepartmentMarker
            department= {dep}
          />
        );
      })}
      {mapData.emps.map((emp) => {
        const department= mapData.departments.filter((dep)=>{return parseInt(emp.employee.id) === dep.id})
        return <EmployeeMarker employee={emp} department={department}/>;
      })}

    </GoogleMap>
  ) : (
    <></>
  );
};


type DepartmentMarkerData={
  department:Department
}
export const DepartmentMarker = (data:DepartmentMarkerData) => {
  const position= {lat:data.department.lat, lng:data.department.lng};
  return (
    <>
      <Marker
        icon={{
          path: "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
          fillColor: data.department.color,
          fillOpacity: 1,
          strokeColor: "#000",
          strokeWeight: 1,
          scale: 1,
        }}
        position={position}
        label={data.department.name}
      />
      <Circle center={position} radius={data.department.radius} />
    </>
  );
};


const EmployeeMarker = ({ employee , department}) => {
  // console.log(department)
  const position = {
    lat: parseFloat(employee.lat),
    lng: parseFloat(employee.lng),
  };
  return <Marker position={position} label={employee.employee.name} />;
};
