import React, { useRef } from "react";
import { createNewAdmin } from "../../services/postRequests";
import Title from "../../components/title/Title";
import "./index.css";
import { Box, Grid } from "@mui/material";
import { Links } from "../../constants/links";
import { useNavigate } from "react-router";
import { adminData } from "../../store/store";
const CreateAdmin = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const phoneRef = useRef(null);

  const adminRef = useRef(null);
  const hrRef = useRef(null);
  const itRef = useRef(null);
  const analystRef = useRef(null);
  const navigate= useNavigate();

  const register = async() => {
    let name = nameRef.current.value;
    let email = emailRef.current.value;
    let password = passRef.current.value;
    let phone = phoneRef.current.value;
    let admin = adminRef.current.checked;
    let hr = hrRef.current.checked;
    let it = itRef.current.checked;
    let analyst = analystRef.current.checked;
    let object = {
      name: name,
      email: email,
      password: password,
      phone: phone,
      is_Admin: admin,
      is_Analyst: analyst,
      is_HR: hr,
      is_IT: it,
      company_id: adminData.getCompanyId(),
    };
    try{
    await createNewAdmin(object);
    navigate(`${Links.dashboard}${Links.createNew}`)
    }catch(E){
      alert('Error')

    }
  };
  return (
    <div>
      <Title backLink={`${Links.dashboard}${Links.createNew}`} titleName="Create Admin" />
      <div style={{ marginTop: 30 }}>
        <form>
          <Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={8} md={5}>
                <input
                  name="Name"
                  ref={nameRef}
                  placeholder="Name"
                  className="text-input"
                />
              </Grid>
              <Grid item xs={8} md={5}>
                <input
                  name="Phone"
                  type={"tel"}
                  ref={phoneRef}
                  placeholder="Phone"
                  className="text-input"
                />
              </Grid>
              <Grid item xs={8} md={5}>
                <input
                  name="Email"
                  ref={emailRef}
                  placeholder="Email"
                  className="text-input"
                />
              </Grid>
              <Grid item xs={8} md={5}>
                <input
                  name="Password"
                  ref={passRef}
                  type={"password"}
                  placeholder="Password"
                  className="text-input"
                />
              </Grid>


              <Grid item xs={12} md={9} textAlign="center" position="relative" top={20}>
                <input name="Admin" type={"checkbox"} ref={adminRef} />
                <label htmlFor="Admin">Admin</label>

                <input name="Analyst" type={"checkbox"} ref={analystRef} />
                <label htmlFor="Analyst">Analyst</label>

                <input name="HR" type={"checkbox"} ref={hrRef} />
                <label htmlFor="HR">Human Resources</label>

                <input name="IT" type={"checkbox"} ref={itRef} />
                <label htmlFor="IT">Information Technology</label>
              </Grid>
            </Grid>
          </Box>
        </form>
        <div style={{textAlign:"center" ,marginTop:50}}>
          <button onClick={register} style={{
            width:"20%",
            backgroundColor:"#60A7A3",
            color:"white",
            fontFamily:"Exo 2",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "19px",
            textAlign:"center",
            padding:"8px 8px 8px 8px",
            border:"white solid 1px",
            borderRadius:5
            
          }}>Add Admin</button>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
