import React from "react";
import { Department } from "../../store/Models/Department";
import { StyledTableCell, StyledTableRow } from "../../components/TableTitle";

const DepartmentCell = (department: Department) => {
  return (
    <StyledTableRow key={`${department.name}_${department.id}`}>
      <StyledTableCell component="th" scope="row">
        {department.name}
        {/* <Link to={`${Links.dashboard}${Links.employeeList}/${row.id}`} state={{ employee: row }}>{row.name}</Link> */}
      </StyledTableCell>
      <StyledTableCell align="right">{department.arrival}</StyledTableCell>
      <StyledTableCell align="right">{department.leave}</StyledTableCell>
      <StyledTableCell align="right">
        <span
          style={{
            color: department.color,
            backgroundColor: department.color,
            borderRadius: 10,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        ></span>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default DepartmentCell;
