import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { selecteeEmployees } from "../../store/store";
import Calendar from "react-calendar";
import "./css/calendar.css";
import { DashboardContext } from "../dashboard/dashboard";
import {
  createRecurringHoliday,
  createTheHoliday,
} from "../../services/postRequests";

const HolidayData = observer((setDisplay) => {
  const context = useContext(DashboardContext);
  const [date, setDate] = useState([]);
  const [isRecurring, setRecurring] = useState(false);
  const [isPaid, setPaid] = useState(false);
  const [comment, setComment] = useState("");

  // console.log(context);
  useEffect(() => {
    console.log(date);
  }, [date]);

  const handleRecurring = async () => {
    if (date.length !== 2) {
      alert("Choose 2 days!");
      return;
    }
    let days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    let dayName1 = days[date[0].getDay()];
    let dayName2 = days[date[1].getDay()];
    console.log(dayName1);
    console.log(dayName2);
    if (dayName1 === dayName2) {
      alert("2 Days are Identical!");
      return;
    }
    console.log("DID NOT RETURN");
    let emps = selecteeEmployees.getList();
    // console.log(emps);
    for (let i of emps) {
      await createRecurringHoliday(i.id, dayName1, dayName2);
    }
    //createRecurringHoliday
  };
  const createHoliday = async (event) => {
    // console.log(isRecurring);
    if (date.length === 0) {
      alert("Nov Days selected!");
      return;
    }
    if (comment.length === 0) {
      alert("Please enter a name for the holiday!");
      return;
    }
    try {
      context.setDisplay("none");
      if (isRecurring) {
        await handleRecurring();
      } else {
        if (Array.isArray(date)) {
          alert("Choose only one day!");
          return;
        }
        let selectedDate = date.toISOString().split("T")[0];
        let emps = selecteeEmployees.getList();
        for (let emp of emps) {
          await createTheHoliday(emp.id, selectedDate, isPaid, comment);
        }
      }
      alert("DONE!");
    } catch (E) {
      alert("Error!");
    }
  };
  return (
    <Box padding={"1em 5% 1em 5%"}>
      <Grid container direction={"column"} spacing={2} margin="auto" >
        <Grid item textAlign={"center"}>
          <h3 style={{ display: "inline" }}>Add Holiday</h3>
        </Grid>
        <Grid item textAlign={"center"}>
          <p style={{fontFamily:"Exo2_medium",fontSize:"14pt", display:"inline"}}>
            You are adding holidays to{" "}
            <span style={{ color: "#D86F2B" }}>
              {selecteeEmployees.getList().length} employees.
            </span>
          </p>
        </Grid>
        <Grid item>
          <input style={{
            padding:"0.75em",
            width:"70%",
            outline:"none",
            fontFamily:"Exo2_medium",
            fontSize:"14pt",
            borderRadius:"10px",
            border:"#aaaaaa solid 1px"
          }}
            placeholder="Holiday name"
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </Grid>
        <Grid item>01-Select Holiday Duration</Grid>
        <Grid item>
          <Calendar
            onChange={setDate}
            selectRange={true}
            onClickDay={setDate}
          />
        </Grid>
        <Grid item>02-Select Holiday Conditions</Grid>
        <Grid item>
          <span>
            Paid
            <input
              type={"checkbox"}
              onChange={(event) => {
                setPaid(event.target.checked);
              }}
            />
          </span>
          <span style={{ marginLeft: 10 }}>
            Recurring
            <input
              type={"checkbox"}
              onChange={(event) => {
                setRecurring(event.target.checked);
              }}
            />
          </span>
        </Grid>
        <Grid item >
          <button
            className="holiday-btns"
            style={{ color: "#60A7A3", width:"42%" }}
            onClick={(event) => {
              context.setDisplay("none");
              console.log("Pressed!");
            }}
          >
            Discard
          </button>
          <button
            className="holiday-btns"
            style={{ color: "white", backgroundColor: "#60A7A3", width:"42%" }}
            onClick={createHoliday}
          >
            Create Holiday
          </button>
        </Grid>
      </Grid>
    </Box>
  );
});

export default HolidayData;
