import { Box, FormGroup, Grid, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import Title from "../../components/title/Title";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import TableTitle from "../../components/TableTitle";
import { searchItem } from "../../store/store";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";
import { Departments } from "../../store/Models/Department";
import DepartmentCell from "./DepartmentCell";
import { SearchItem } from "../../store/SearchItem";

// import {redirect}
// import FormControl from "@mui/material/FormControl";

const DepartmentList = () => {
  const nav = useNavigate();
  useEffect(() => {
    if(!isTokenDefined())
      nav(NOT_FOUND_URL);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={5} columns={12}>
        <Grid item xs={12}>
          <Title titleName={"Departments List"} />
        </Grid>
        <Grid item style={{ width: "80%" }}>
          <DepartmentCustomizedTable item={searchItem} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default DepartmentList;

type DepartmentCustomizedItem = {
  item: SearchItem;
};
export const DepartmentCustomizedTable = observer(
  (data: DepartmentCustomizedItem) => {
    const [emps, setEmps] = useState([]);

    // const location= useLocation();
    useEffect(() => {
      async function fetchData() {
        try {
          if (emps.length > 0) return;
          const res =
            await Departments.getDepartmentSingleton().getDepartments();
          setEmps(res);
        } catch (err) {
          // console.log(err);
        }
      }
      fetchData();
    }, [emps]);

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={10} direction="column">
          <Grid item>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableTitle
                  listOfNames={["Name", "Arrival Time", "Leave Time", "Color"]}
                />
                <TableBody>
                  {emps
                    .filter((elem) => {
                      let text = data.item.getText().toLowerCase();
                      // console.log(elem.color)
                      return elem.name.toLowerCase().includes(text);
                    })
                    .map(DepartmentCell)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);
