import axios_api from "../../../services/api";
import { isDefined } from "../../../services/library";
import { adminData } from "../../store";
import { Selector } from "../api";
import { Departments } from "../Department";
import { Employees } from "../Employee";
import {
  AnnouncementMessage,
  DepartmentMessage,
  Message,
  PrivateMessage,
} from "./Message";

abstract class SingleTypeMessages<T extends Message<any>>
  implements Selector<T>
{
  protected messages: T[] = [];
  protected abstract requestForMessages();
  public async getMessages() {
    if (this.messages.length === 0) await this.requestForMessages();
    return this.messages;
  }
  getByID(id: number): T {
    let data=this.messages.filter((msg: AnnouncementMessage) => {
      // eslint-disable-next-line eqeqeq
      return msg.getID() == id;
    });
    return data.length>0 ? data[0]:null;
  }
}

export class AnnouncementMessages extends SingleTypeMessages<AnnouncementMessage> {
  protected async requestForMessages() {
    const { data } = await axios_api.get("/getannounc/" + adminData.getId());
    for (let i of data) {
      let msg = new AnnouncementMessage(i.text, adminData.getId());
      msg.setID(i.id);
      this.messages.push(msg);
    }
  }
}

export class DepartmentMessages extends SingleTypeMessages<DepartmentMessage> {
  protected async requestForMessages() {
    await Departments.getDepartmentSingleton().getDepartments();
    
    const { data } = await axios_api.get("getallmsgdep/" + adminData.getId());
    console.log(data);
    for (let i of data) {
      let msg = new DepartmentMessage(
        Departments.getDepartmentSingleton().getByID(i.department_id),
        i.text,
        adminData.getId()
      );
      msg.setID(i.id);
      this.messages.push(msg);
    }
  }
}

export class PrivateMessages extends SingleTypeMessages<PrivateMessage> {
  protected async requestForMessages() {
    await Employees.getSingletonEmployees().getEmployees();
    const { data } = await axios_api.get("getallmsgemp/" + adminData.getId());
    console.log(data);
    for (let i of data) {
      let msg = new PrivateMessage(
        Employees.getSingletonEmployees().getByID(i.employee_id),
        i.text,
        adminData.getId()
      );
      msg.setID(i.id);
      this.messages.push(msg);
    }
  }
}
