import axios from 'axios';
import { adminData } from '../store/store';

const BASE_URL= "https://metatrackeregypt.com/api/";
// const BASE_URL= 'http://192.168.1.237:2222/api'
// const BASE_URL= "http://localhost:5000/";

const axios_api= axios.create({baseURL: BASE_URL, headers:{
    "Access-Control-Allow-Origin": "*"
}}); 
export default axios_api;



export const getAuthorizedHeader= ()=> {return {headers:{Authorization:`Bearer ${adminData.getToken()}`}}}
export const getAuthorized= ()=> `Bearer ${adminData.getToken()}`