import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.module.css';
import './pages/global.css'
import Routers from '../routes';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routers/>
      </BrowserRouter>
    </div>
  );
}

export default App;
