import React, { useRef } from "react";
import { Box, FormGroup, Grid } from "@mui/material";
import { isValidNumber } from "../../services/library";
import { InputLabel, Input, Button } from "@mui/material";
import { Department, Departments } from "../../store/Models/Department";
import { Links } from "../../constants/links";
import { useNavigate } from "react-router";
import Title from "../../components/title/Title";

const CreateDepartment = () => {
  return (
    <div>
      <Title titleName={"Create Department"} backLink={`${Links.dashboard}${Links.createNew}`}/>
      <DepartmentForm />
    </div>
  );
};

const DepartmentForm = () => {
  const nameElem = useRef(null);
  const arrTimeElem = useRef(null);
  const leaveTimeElem = useRef(null);
  const longElem = useRef(null);
  const latElem = useRef(null);
  const radElem = useRef(null);
  const colorElem = useRef(null);
  

  const navigate=useNavigate();

  const handleForm = async (event) => {
    const department = new Department(
      -1,
      nameElem.current.value,
      arrTimeElem.current.value,
      leaveTimeElem.current.value,
      colorElem.current.value,
      latElem.current.value,
      longElem.current.value,
      radElem.current.value
    );

    // console.log(object);
    // let operationDone=false;

    if (
      isValidNumber(department.lng) &&
      isValidNumber(department.lat) &&
      isValidNumber(department.radius)
    )
    try{
      await department.create();
      Departments.getDepartmentSingleton().reset();
      navigate(`${Links.dashboard}${Links.createNew}`)
    }catch(E){
      alert('ERROR')

    }
  };

  return (
    <>
      <h4>Create new Department</h4>
      <FormGroup>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} direction="column" marginBottom={10}>
            <Grid item container spacing={5}>
              <Grid item>
                <InputLabel htmlFor="dep-name-input">
                  Department Name
                </InputLabel>
                <Input
                  inputRef={nameElem}
                  id="dep-name-input"
                  aria-describedby="my-helper-text"
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="dep-color-input">
                  Department Color
                </InputLabel>
                <input
                  ref={colorElem}
                  type="color"
                  id="dep-color-input"
                  aria-describedby="my-helper-text"
                  // width={100}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item>
                <InputLabel htmlFor="arr-time-input">Arrival Time</InputLabel>
                <Input
                  inputRef={arrTimeElem}
                  id="arr-time-input"
                  type="number"
                  aria-describedby="my-helper-text"
                  defaultValue={12}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="leave-time-input">Leave Time</InputLabel>
                <Input
                  inputRef={leaveTimeElem}
                  type="number"
                  id="leave-time-input"
                  aria-describedby="my-helper-text"
                  defaultValue={12}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item>
                <InputLabel htmlFor="latitude-input">Latitude</InputLabel>
                <Input
                  inputRef={latElem}
                  type="number"
                  id="latitude-input"
                  aria-describedby="my-helper-text"
                  defaultValue={0}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="longitude-input">Longitude</InputLabel>
                <Input
                  inputRef={longElem}
                  type="number"
                  id="longitude-input"
                  aria-describedby="my-helper-text"
                  defaultValue={0}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="radius-input">Radius</InputLabel>
                <Input
                  inputRef={radElem}
                  type="number"
                  id="radius-input"
                  aria-describedby="my-helper-text"
                  defaultValue={0}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleForm}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormGroup>
    </>
  );
};

export default CreateDepartment;
