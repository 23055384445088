import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bolder",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#2E3133",
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableInnerTitles = ({ listOfNames }) => {
  const list = useState(listOfNames)[0];

  const mapFunction = (element, index) => {
    if (index === 0) return <StyledTableCell style={{fontFamily:"Exo2"}}>{element}</StyledTableCell>;
    return <StyledTableCell align="right" style={{fontFamily:"Exo2"}}>{element}</StyledTableCell>;
  };
  return <> {list.map(mapFunction)}</>;
};

const TableTitle = ({ listOfNames }) => {
  return (
    <TableHead>
      <TableRow><TableInnerTitles listOfNames={listOfNames} /> </TableRow>
    </TableHead>
  );
};

export default TableTitle;
