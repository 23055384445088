export const PIE_LABELS= {
    IN_ZONE: "In Zone",
    OUT_ZONE: "Out Zone",
    ABSENT: "Absents "
}

export const UserData= [
    {
        id: 1,
        year: 2016,
        userGain: 80000,
        userLost: 823,
    },
    {
        id: 2,
        year: 2017,
        userGain: 45677,
        userLost: 345,
    },
    {
        id: 3,
        year: 2018,
        userGain: 45677,
        userLost: 345,
    }
]