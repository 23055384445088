import { NavigationNames } from "../../constants/navigation";
import { Links } from "../../constants/links";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SelectedDashboard from "../../images/NavigationBar/Dashboard/selected.svg";
import UnSelectedDashboard from "../../images/NavigationBar/Dashboard/unselected.svg";
import SelectedMap from "../../images/NavigationBar/Map/Selected.svg";
import UnSelectedMap from "../../images/NavigationBar/Map/Unselected.svg";
import UnSelectedEmployee from "../../images/NavigationBar/Employees/Selected.svg";
import SelectedEmployee from "../../images/NavigationBar/Employees/Unselected.svg";
import SelectedSendMessage from "../../images/NavigationBar/SendMessage/Selected.svg";
import UnSelectedSendMessage from "../../images/NavigationBar/SendMessage/Unselected.svg";
import SelectedCreateNew from "../../images/NavigationBar/CreateNew/Selected.svg";
import UnSelectedCreateNew from "../../images/NavigationBar/CreateNew/Unselected.svg";
import SelectedPayroll from "../../images/NavigationBar/Payroll/Selected.svg";
import UnSelectedPayroll from "../../images/NavigationBar/Payroll/Unselected.svg";
import AllInboxIcon from '@material-ui/icons/AllInbox';


import NavSpan from "./NavSpan";

import { Link } from "react-router-dom";

type ComponentData = {
  isOnRightPath: boolean;
};

export abstract class NavigatorButton {
  public appearingName: string;
  private isOnPath: boolean;
  private rightPath: string;
  constructor(currentPath, rightPath) {
    this.rightPath = rightPath;
    this.isOnPath = currentPath === rightPath;
  }
  public setCurrentPath(path) {
    this.isOnPath = path === this.rightPath;
  }
  public isOnRightPath(): boolean {
    return this.isOnPath;
  }
  abstract Component(data: ComponentData);
}

export class Dashboard extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, Links.dashboard);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath
      ? SelectedDashboard
      : UnSelectedDashboard;
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={Links.dashboard}>
          <img src={image_src} alt="" />
          {/* <GridViewIcon style={{ color: isRightPath.isOnRightPath ? "#ffffff" : "#A5A195" }} /> */}
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.dashboard}
          />
        </Link>
      </li>
    );
  }
}

export class EmployeeMap extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.map}`);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath ? SelectedMap : UnSelectedMap;
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.map}`}>
          <img src={image_src} alt="" />
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.employeeMap}
          />
        </Link>
      </li>
    );
  }
}

export class EmployeeList extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.employeeList}`);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath
      ? SelectedEmployee
      : UnSelectedEmployee;
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.employeeList}`}>
          <img src={image_src} alt="" />
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.employeeList}
          />
        </Link>
      </li>
    );
  }
}

export class DepartmentList extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.departmentList}`);
  }
  Component(isRightPath: ComponentData) {
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.departmentList}`}>
          <AccountTreeIcon
            style={{ color: isRightPath.isOnRightPath ? "#ffffff" : "#A5A195" }}
          />
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.departmentList}
          />
        </Link>
      </li>
    );
  }
}

export class SendMessage extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.sendMessage}`);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath
      ? SelectedSendMessage
      : UnSelectedSendMessage;
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.sendMessage}`}>
          <img src={image_src} alt="" />
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.sendMessage}
          />
        </Link>
      </li>
    );
  }
}
export class MessagesList extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.messagesList}`);
  }
  Component(isRightPath: ComponentData) {
    let color= isRightPath.isOnRightPath ? "#FFFFFF":"#aaaaaa";
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.messagesList}`}>
          <AllInboxIcon style={{color:color}}/>
          {/* <img src={image_src} alt="" /> */}
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.messagesList}
          />
        </Link>
      </li>
    );
  }
}
export class CreateNew extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.createNew}`);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath
      ? SelectedCreateNew
      : UnSelectedCreateNew;
    return (
      <li>
        <Link style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.createNew}`}>
          <img src={image_src} alt="" />
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.createNew}
          />
        </Link>
      </li>
    );
  }
}

export class Payroll extends NavigatorButton {
  constructor(currentPath) {
    super(currentPath, `${Links.dashboard}${Links.payroll}`);
  }
  Component(isRightPath: ComponentData) {
    let image_src = isRightPath.isOnRightPath
      ? SelectedPayroll
      : UnSelectedPayroll;
    return (
      <li>
        <Link  style={{textDecoration:"none"}} to={`${Links.dashboard}${Links.payroll}`}>
          <img src={image_src} alt=""/>
          <NavSpan
            isRightPath={isRightPath.isOnRightPath}
            name={NavigationNames.payroll}
          />
        </Link>
      </li>
    );
  }
}
