import React, { useEffect } from "react";
import DashStats from "./Stats";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Title from "../../components/title/Title";
import { useNavigate } from "react-router";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";

const DashboardData = () => {
  // console.log('Called')
  const nav = useNavigate();
  useEffect(() => {
    // console.log('HEREEEEEE');
    if (!isTokenDefined()) nav(NOT_FOUND_URL);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column">
        <Grid item>
          <Title titleName="Dashboard" />
        </Grid>
      </Grid>
      <Grid item>
        <DashStats />
      </Grid>
    </Box>
  );
};

export default DashboardData;
