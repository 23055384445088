import React, { useEffect, useState } from 'react'
import {Chart} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getMonthlyIns, getMonthlyOuts } from '../../services/getRequests';

const getData=(ins,outs)=>{
    return {
            labels: [...Array(12).keys()],
            datasets: [{
                label: 'In Zone',
                data: ins,
                // backgroundColor: "#0000ff",
                // fill: false,
                borderColor: '#60A7A3',
                tension: 0.5,
            },
            {
                label: 'Out Zone',
                data: outs,
                borderColor:"#D86F2B",
                tension:0.5
            },
            ],
            options:{
                maintainAspectRatio: true,
            }
        }

    }
const LineChart = () => {
    // const [ins,setIns]= useState([]);
    // const [outs,setOuts]= useState([]);
    const [dat, setDat]= useState(getData([1,2,3,4,5],[1,4,5,6]))

    useEffect(()=>{
        const fetchData= async()=>{
            const ins= await getMonthlyIns();
            const outs= await getMonthlyOuts();
            // console.log(ins);
            setDat(getData(ins,outs));
            // console.log(dat);
        }
        fetchData();
    },[]);

    // console.log(dat)
  return (
    <Line data= {dat}  height={274} width={989} />
  )
}

export default LineChart
