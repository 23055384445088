import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import MetaImage from "../../images/smalMetaTracker.svg";
import SettingsImage from "../../images/NavigationBar/admins/settings.svg";
import ExitImage from "../../images/NavigationBar/admins/exit.svg";

import {
  CreateNew,
  Dashboard,
  DepartmentList,
  EmployeeList,
  EmployeeMap,
  SendMessage,
  MessagesList,
  Payroll,
} from "./NavigatorButton";
import { useLocation, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import "../../css/components/navButtons.css";
import { adminData } from "../../store/store";
import { textAlign } from "@mui/system";
import { Employees } from "../../store/Models/Employee";
import { Departments } from "../../store/Models/Department";
import { CheckedInEmployees } from "../../store/Models/CheckedInEmployees";

// const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const MyDrawer = () => {
    const location = useLocation();
    console.log("pathname", location.pathname);
    const navList = [
      new Dashboard(location.pathname),
      new EmployeeMap(location.pathname),
      new EmployeeList(location.pathname),
      new DepartmentList(location.pathname),
      new SendMessage(location.pathname),
      new MessagesList(location.pathname),
      new CreateNew(location.pathname),
      new Payroll(location.pathname),
    ];

    const [getList, setList] = React.useState(navList);
    const [state, setState] = React.useState(false);
    const [val, setVal] = React.useState(0);
    const [getSelected, setSelected] = React.useState<boolean[]>(
      navList.map((elem) => elem.isOnRightPath())
    );

    React.useEffect(() => {
      for (let i = 0; i < navList.length; i++) {
        navList[i].setCurrentPath(location.pathname);
      }
    });

    const toggleDrawer =
      (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setState(!state);
      };

    return (
      <div style={{padding:"0 1em 0 1em"}}>
        <div style={{ position: "relative" }}>
          <img
            src={MetaImage}
            alt="Bruh"
            style={{
              position: "relative",
              width: "80%",
              marginTop: "1em",
              marginBottom:"2em",
              left: "10%",
            }}
          />
          <List>
            {getList.map((element, index) => {
              return (
                <ListItem
                  key={`${index}-navlist`}
                  onClick={() => {
                    let newList = new Array(getSelected.length).fill(false);
                    newList[index] = true;
                    setSelected(newList);
                    setVal(val + 1);
                  }}
                  style={{ marginBottom: "1.5em" }}
                >
                  <element.Component isOnRightPath={getSelected[index]} />
                </ListItem>
              );
            })}
          </List>
        </div>
        <AdminComponent />
      </div>
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        id="appBar-nav"
        sx={{
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
          // ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#2E3133",
        }}
      >
        <MenuIcon onClick={handleDrawerToggle} style={{ color: "#60A7A3" }} />
      </AppBar>
      <Box
        component="nav"
        sx={{ flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", xl: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#2E3133",
            },
          }}
        >
          <MyDrawer />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", xl: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#2E3133",
            },
          }}
          open
        >
          <MyDrawer />
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;

const AdminComponent = () => {
  const navigate= useNavigate();
  return (
    <div
      style={{
        position: "relative",
        marginTop: "5em",
        marginBottom: "1em",

        width: "80%",
        minHeight: "100px",
        // left:"10%",
        // right:"10%",
        textAlign: "center",
      }}
    >
      <p id="admin-name-id">{adminData.getName()}</p>
      <p id="admin-email-id">{adminData.getEmail()}</p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button style={{ background: "none", border: "none" }}>
          <img src={SettingsImage} alt="" />
        </button>
        <button style={{ background: "none", border: "none" }} onClick={(event)=>{
          adminData.emptyAdmin();
          Employees.getSingletonEmployees().resetEmployees();
          Departments.getDepartmentSingleton().reset();
          CheckedInEmployees.getSingletonInstance().reset();
          navigate("/");
        }}>
          <img src={ExitImage} alt="" />
        </button>
      </div>
    </div>
  );
};
