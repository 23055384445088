import React, { createContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Title from "../../components/title/Title";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import EmployeeTable from "./EmployeeTable";
import { Map } from "./map";
import { useNavigate } from "react-router";
import { isDefined, isTokenDefined, NOT_FOUND_URL } from "../../services/library";
import { Department, Departments } from "../../store/Models/Department";
import {
  CheckedInEmployee,
} from "../../store/Models/EmployeeLocation";
import { observer } from "mobx-react-lite";
import { CheckedInEmployees } from "../../store/Models/CheckedInEmployees";
import RefreshButton from "./RefreshButton";


const context= createContext(0);


const EmployeeMap = observer(() => {
  const [departments, setDeps] = useState([]);
  const [emps, setEmps] = useState([]);
  const [depIndex, setDepIndex] = useState(0);
  const [counter,setCounter]= useState(0);
  const nav = useNavigate();

  const filterEmps = (elem: CheckedInEmployee) => {
    if (depIndex<0) return true;
    return elem.getEmployee().departmentID == depIndex;
  };

  useEffect(() => {
    if (!isTokenDefined()) nav(NOT_FOUND_URL);
    const fetchEmps = async () => {
      setEmps(
        await CheckedInEmployees.getSingletonInstance().getCheckedInEmployees()
            );
  };
    const fetchDeps = async () => {
      if(departments.length > 0)
        return;
      setDeps(await Departments.getDepartmentSingleton().getDepartments());
      setDepIndex(0);
    };
    fetchDeps();
    fetchEmps();
  },[counter]);

  const getDepartments = () => {
    if (depIndex<0||departments.length===0) return departments;
    return [Departments.getDepartmentSingleton().getByID(depIndex)];
  };
  const getButton=()=>{
    return <RefreshButton setter={setCounter} counter={counter}/>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      
      <Grid container direction="column" spacing={10} columns={12}>
        <Grid item>  
          <Title Button={getButton} titleName="Employees Map" />
        </Grid>
      </Grid>
      <Grid item>
        {depIndex}
        <SelectDepartment
          setter={(value) => {
            setDepIndex(value);
          }}
          deps={departments}
        />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={6}>
          <Map departments={getDepartments()} emps={emps.filter(filterEmps)} />
        </Grid>
        <Grid item xs={12} md={5}>
          <div
            style={{
              backgroundColor: "rgba(96,167,163,0.15)",
              width: "100%",
              height: "100%",
              // opacity:"5%"
            }}
          >
            <EmployeeTable emps={emps.filter(filterEmps)} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
});

export default EmployeeMap;

type SelectDepartmentData = {
  setter: Function;
  deps: Department[];
};

function SelectDepartment(data: SelectDepartmentData) {
  if (data.deps.length === 0) return <></>;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [index, setIndex] = useState(data.deps[0].id);

  const handleChange = (event: SelectChangeEvent) => {
    data.setter(event.target.value);
    setIndex(Number.parseInt(event.target.value));
    // return event.target.value;
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">
          Department
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={index + ""}
          onChange={handleChange}
          label="Department"
        >
          {data.deps.map((department) => {
            return <MenuItem value={department.id}>{department.name}</MenuItem>;
          })}
          <MenuItem value={-1}>All</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
