import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "../../css/components/message.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { isDefined } from "../../services/library";
import { Indexable } from "../../store/Models/api";

export const VALUES = ["Announcement", "Department", "Employee"];

export default function MessageBreadCrumb({ setter }) {
  return (
    <div>
        {VALUES.map((value, index) => {
          return (
            <button
              className="breadCrumbSpan"
              style={{margin:5, borderRadius:5, borderColor:"white", color:"white", backgroundColor:"#60A7A3", fontSize:"16pt", fontFamily:"Exo2_medium"}}
              onClick={(event) => {
                setter(index);
              }}
            >
              {value}
            </button>
          );
        })}
    </div>
  );
}

type SelectIndexData={
  data:Indexable[],
  name:string,
  setter:Function,
  defaultValue?
}
export function SelectIndex(data:SelectIndexData) {

  const handleChange = (event) => {
    data.setter(event.target.value);
    // console.log(event.target.value);
  };
//   console.log(data);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ minWidth: 250 }}>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {data.name}
        </InputLabel>
        <NativeSelect onChange={handleChange} defaultValue={isDefined(data.defaultValue)? data.defaultValue : data.data[0]}>
          {data.data.map((elem) => {
            return <option value={elem.getID()}>{elem.getName()}</option>;
          })}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}
