import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Links } from "../../constants/links";
import "./css/signInForm.css";
import { signIn } from "../../services/postRequests";
import { AdminData } from "../../store/Admin";
import { adminData } from "../../store/store";
const SignInForm = () => {
  const [username, setUsername] = useState("omayaa@gmail.com");
  const [password, setPassword] = useState("19891989");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    // console.log(username);
    // console.log(password);
    try {
      const data = (await signIn(username, password)) as unknown as AdminData;
      adminData.setAdmin(data);
      console.log(adminData);
      navigate(Links.dashboard);
    } catch {
      alert("Although sign in failed, but you will be logged in also :)");
    }
    // console.log(adminData)
  };
  return (
    <div style={{ paddingLeft: 0 }}>
      <form className="sign-form">
        <input
          className="sign-in-text-input"
          type="text"
          placeholder="Username"
          value={username}
          required
          onChange={(s) => {
            setUsername(s.target.value);
          }}
        />
        <input
          className="sign-in-text-input"
          type="password"
          placeholder="Password"
          value={password}
          required
          onChange={(s) => {
            setPassword(s.target.value);
          }}
        />
        <div id="check-forget" style={{width:"73%"}}>
          <span id="check">
            <input type="checkbox" id="remember-check"/>
           <span style={{marginLeft:".2em", color:"#60a7a3", fontWeight:"bold"}}> Remember Me </span>
          </span>
          <span id="forget">Forgot password?</span>
        </div>
        {/* <Link to={Links.dashboard}> */}
        <input
          id="sign-submit"
          type="button"
          value="Sign In"
          onClick={handleSubmit}
        />
        {/* </Link> */}
      </form>
    </div>
  );
};

export default SignInForm;
