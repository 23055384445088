import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { CheckedInEmployee } from "../../store/Models/EmployeeLocation";

const headStyle = {
  fontFamily: "Exo2_bold",
  fontSize: "16pt",
  color: "#2E3133",
};
const bodyStyle = {
  fontFamily: "Exo2_medium",
  fontSize: "14pt",
};
type EmployeeTableData={
  emps:CheckedInEmployee[]
}
const EmployeeTable = (data:EmployeeTableData) => {
  return (
    <Table style={{ backgroundColor: "white", borderRadius: "1em" }}>
      <TableHead>
        <TableRow>
          <TableCell style={headStyle}>Name</TableCell>
          <TableCell style={headStyle}>Start Time</TableCell>
          <TableCell style={headStyle}>End Time</TableCell>
          <TableCell style={headStyle}>Zone</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.emps.map((element:CheckedInEmployee) => {
          return (
            <TableRow>
              <TableCell style={{ ...bodyStyle, color: "#60A7A3" }}>
                {element.getEmployee().name}
              </TableCell>
              <TableCell style={{ ...bodyStyle }}>{element.getStartTime()}</TableCell>
              <TableCell style={{ ...bodyStyle }}>{element.getEndTime()}</TableCell>

              <TableCell style={{ ...bodyStyle }}>{element.isInside() ? "Inside" : "Outside" }</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default EmployeeTable;
