import axios_api from "../../services/api";
import { adminData } from "../store";
import { Employee, Employees } from "./Employee";
import { CheckedInEmployee } from "./EmployeeLocation";
import { makeAutoObservable } from "mobx";
import { isDefined } from "../../services/library";

export class CheckedInEmployees {
  private checkedInEmployees: CheckedInEmployee[];
  private static instance: CheckedInEmployees = null;
  private constructor() {
    this.checkedInEmployees = null;
    makeAutoObservable(this);
  }

  public reset() {
    this.checkedInEmployees = null;
  }
  public async getCheckedInEmployees() {
    console.log("Called in CHECKEDDDD!!");
    console.log(this.checkedInEmployees)
    if (this.checkedInEmployees === null || this.checkedInEmployees.length<=1)
      await this.requestForCheckedInEmployees();
    return this.checkedInEmployees;
  }
  public include(emp:Employee){
    if(this.checkedInEmployees === null)
      return false;
    return this.checkedInEmployees.filter((employee:CheckedInEmployee)=>{return emp.id==employee.getEmployee().id}).length > 0;
    
  }
  private async requestForCheckedInEmployees() {
    await Employees.getSingletonEmployees().getEmployees(); //Needed to be called before doing anything.
    this.checkedInEmployees = [];
    const { data } = await axios_api.get("/check_in_today/"+adminData.getCompanyId(), {
      headers: { Authorization: `Bearer ${adminData.getToken()}` },
    });
    for (let element of data) {
      let checkedEmployee = new CheckedInEmployee(element.employee_id);
      checkedEmployee.setLat(Number.parseFloat(element.lat));
      checkedEmployee.setLng(Number.parseFloat(element.lng));
      checkedEmployee.setEndTime(element.End_time);
      checkedEmployee.setStartTime(element.Start_time);

      if (isDefined(checkedEmployee.getEmployee())){
        console.log(`${checkedEmployee.getEmployee().name} pushed!`);
        this.checkedInEmployees.push(checkedEmployee);
      }
    }
  }
  public static getSingletonInstance(): CheckedInEmployees {
    if (CheckedInEmployees.instance === null)
      CheckedInEmployees.instance = new CheckedInEmployees();
    return CheckedInEmployees.instance;
  }
}
