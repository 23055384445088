import React, { useState } from "react";

type SpanData = {
  isRightPath: boolean;
  name: string;
};

const getColor = (correct: boolean): string => {
  return correct ? "#60A7A3" : "#A5A195";
};

const NavSpan = (data: SpanData) => {
  // const [color, setColor]= useState(getColor(data.isRightPath));

  return (
    <span
      style={{
        color: getColor(data.isRightPath),
        marginLeft: 10,
        fontFamily: "Exo2",
        fontWeight: "bold",
        fontSize: "14pt",
        position: "relative",
        bottom: 3,
      }}
    >
      {data.name}
    </span>
  );
};

export default NavSpan;
