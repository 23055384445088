import { makeObservable, observable, action } from "mobx"

export class AdminData {
    id: number;
    api_admin_token: string; 
    name:string;
    company_id:string;
    email:string;

    constructor() {
        makeObservable(this, {
            id: observable,
            api_admin_token: observable,
            name:observable,
            email:observable,

            getId: action,
            getToken:action,
            getName:action,
            getCompanyId:action,
            setId: action,
            setToken:action,
            setCompanyId:action,
            setName:action,
            setAdmin:action,
            setEmail:action,
            getEmail:action,
            emptyAdmin:action
        })
    }

    getId(){return this.id};
    getToken(){return this.api_admin_token};
    getName(){return this.name};
    getCompanyId(){return this.company_id};
    getEmail=()=>this.email;

    setId(id){this.id= id};
    setToken(session){this.api_admin_token= session};
    setName(name){this.name=name};
    setCompanyId(id){this.company_id=id};
    setEmail(email){this.email= email;}

    setAdmin(admin:AdminData){
        this.id= admin.id;
        this.name= admin.name;
        this.company_id= admin.company_id;
        this.api_admin_token= admin.api_admin_token;
        this.email= admin.email;
        console.log(this);
    }
    emptyAdmin(){
        this.setCompanyId(undefined);
        this.setId(undefined);
        this.setToken(undefined);
        this.setName(undefined);
        this.setEmail(undefined);
    }



}