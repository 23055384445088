import axios from "axios";
import { AdminData } from "../store/Admin";
import { adminData } from "../store/store";
import axios_api, { getAuthorized } from "./api";
import FormData from "form-data";

export const signIn = async (username, password) => {
  // username = "omayaa@gmail.com";
  // password = "19891989";
  // username='alaaa@gmail.com';
  // password='12345678';

  const { data } = await axios_api.post("/admin/login", {
    email: username,
    password: password,
  });
  console.log(data.admin);
  return data.admin;
};

export const createEmployeesFromList = async (list) => {
  const protocol = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${adminData.getToken()}`,
    },
    emp: list,
  };
  console.log(protocol);
  try {
    await axios_api.post("/list-of-employees", protocol);
  } catch (e) {
    console.log(e);
  }
};

export const createNewAdmin = async (object) => {
  console.log(object);
  await axios_api.post("/admin/register", object);
};

export const createRecurringHoliday = async(id, day1, day2) => {
  let object = {
    empID: id,
    day1: day1,
    day2: day2,
  };
  await axios_api.post("/week", object);
};

export const createTheHoliday = async(id, date, isPaid, comment) => {
  const object = {
    employee_id: id,
    day: date,
    Is_paid: isPaid,
    comment: comment,
  };
  console.log(object);
  await axios_api.post('/store-holi',object);
};
