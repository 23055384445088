import { Box, Grid } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import Title from "../../components/title/Title";
import { searchItem } from "../../store/store";
import { DashboardContext } from "../dashboard/dashboard";
import { EmployeeListTable } from "./EmployeeListTable";
import CSS from "./employees.module.css";
import { useNavigate } from "react-router";
import { isTokenDefined, NOT_FOUND_URL } from "../../services/library";


const AddHoliday = () => {
  const [active, setActive] = useState(false);
  const x= useContext(DashboardContext);

  return (
    <>
      <button
        className={CSS.add_holiday_btn}
        style={{
          border: "#60A7A3 solid 1px",
          borderRadius: 5,
          width: "50%",
          height: "100%",
          color: "#60A7A3",
          fontFamily: "Exo 2",
          backgroundColor: active ? "#aaaaaa" : "#ffffff",
          fontWeight: "bold",
          padding: 10,
        }}
        onMouseDown={(event) => {
          setActive(true);
        }}
        onMouseUp={(event) => {
          setActive(false);
          x.setDisplay("block");
        }}
      >
        Add Holiday
      </button>
    </>
  );
};

const EmployeeList = () => {
  const nav= useNavigate();
  useEffect(()=>{
    if(!isTokenDefined())
      nav(NOT_FOUND_URL);
  },[])

  return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" spacing={10} columns={12}>
            <Grid item>
              <Title titleName="Employees List" Button={AddHoliday} />
            </Grid>
        </Grid>
        <Grid item style={{ width: "90%" }}>
          <EmployeeListTable item={searchItem} />
        </Grid>
      </Box>
  );
};

export default EmployeeList;
