import { makeAutoObservable } from "mobx"

export class SecondDashboard{
    inZoneLate:number= 0;
    inZoneRemote:number= 0;
    absentNoExcuse:number= 0;
    absentHoliday: number= 0;

    constructor(){
        makeAutoObservable(this);
    }

    setInZoneLate(num){
        this.inZoneLate= num;
    }
    setAbsentsNoExcuse(num){this.absentNoExcuse= num;}
    setInZoneRemote(num){this.inZoneRemote= num;}
    setAbsentHoliday(num){this.absentHoliday= num;}

    getInZoneLate() {return this.inZoneLate;}
    getAbsentNoExcuse() {return this.absentNoExcuse;}
    getInZoneRemote() {return this.inZoneRemote;}
    getAbsentHoliday() {return this.absentHoliday;}
}

export const secondDashboardData= new SecondDashboard();