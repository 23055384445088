import { Selector } from "../api";
import {
  Message,
} from "./Message";
import {
  AnnouncementMessages,
  DepartmentMessages,
  PrivateMessages,
} from "./SingleTypeMessages";

export class Messages implements Selector<Message<any>> {
  private static instance: Messages = null;
  private announcementMessages: AnnouncementMessages;
  private departmentMessages: DepartmentMessages;
  private privateMessages: PrivateMessages;

  private constructor() {
    this.announcementMessages = new AnnouncementMessages();
    this.departmentMessages = new DepartmentMessages();
    this.privateMessages = new PrivateMessages();
  }

  public getByID(id: number): Message<any> {
    let data = this.announcementMessages.getByID(id);
    if (data !== null) return data;
    data = this.departmentMessages.getByID(id);
    if (data !== null) return data;
    return this.privateMessages.getByID(id);
  }

  public async getAnnouncementMessages() {
    return await this.announcementMessages.getMessages();
  }
  public async getDepartmentMessages() {
    return await this.departmentMessages.getMessages();
  }
  public async getPrivateMessages() {
    return await this.privateMessages.getMessages();
  }
  public async getAllMessages(){
    let messages= await this.getAnnouncementMessages();
    messages=messages.concat(await this.getDepartmentMessages());
    // console.log(await this.getDepartmentMessages());
    messages=messages.concat(await this.getPrivateMessages());
    return messages;
  }


  public static getSingletonInstance(): Messages {
    if (this.instance === null) this.instance = new Messages();
    return this.instance;
  }
}
