import { Box, Grid } from "@mui/material";
import  { useEffect, useState } from "react";
import {
  getCurrentAbsentNumber,
  getOutZoneNumber,
  getInZoneLateNumber,
  getNumberAbsentHoliday,
} from "../../../../services/getRequests";
import { secondDashboardData } from "../../../../store/Dashboards/SecondDashboard";
import OutZoneNoExcuseImg from "../../../../images/adminBoxes/OutZoneNoExcuse.svg";
import OutZoneHolidayImg from "../../../../images/adminBoxes/OutZoneHoliday.svg";
import InZoneLateImg from "../../../../images/adminBoxes/InZone-Late.svg";
import InZoneWorkAwayImg from "../../../../images/adminBoxes/InZoneWorkAway.svg";
import AdminBoxSpan from "../FirstChart/AdminBox";

const CONSTANTS= {
  IN_ZONE_LATE:0,
  IN_ZONE_REMOTE:3,
  ABSENT_HOLIDAY:2,
  ABSENT_NO_EXCUSE:1
};


const getDataAndSet = async (api_get, set, index) => {
  const data = await api_get();
  if (data > 0) set(data);
  switch (index) {
    case CONSTANTS.IN_ZONE_LATE:
      secondDashboardData.setInZoneLate(data);
      break;
    case CONSTANTS.ABSENT_HOLIDAY:
      secondDashboardData.setAbsentHoliday(data);
      break;
    case CONSTANTS.ABSENT_NO_EXCUSE:
      secondDashboardData.setAbsentsNoExcuse(data);
      break;
    case CONSTANTS.IN_ZONE_REMOTE:
      secondDashboardData.setInZoneRemote(data);
      break;
    default:
      break;
  }
};
export function InZoneLate() {
  // console.log("Total Employees Component called!");
  const [empNumber, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getInZoneLateNumber, setNumber, CONSTANTS.IN_ZONE_LATE);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4} justifyContent={"center"}>
          <img
            src={InZoneLateImg}
            className="iconStyle"
            style={{ width: "5em" }}
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid item xs={8}>
        <AdminBoxSpan name="In Zone-Late" number={empNumber} /></Grid>
      </Grid>
    </Box>
  );
}

export function OutZoneHoliday() {
  const [absNumber, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getNumberAbsentHoliday, setNumber, CONSTANTS.ABSENT_HOLIDAY);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <img
            src={OutZoneHolidayImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid item xs={8}>
          <AdminBoxSpan name="Absent Holiday" number={absNumber} />
        </Grid>
      </Grid>
    </Box>
  );
}

export function OutZoneNoExcuse() {
  const [inZones, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getCurrentAbsentNumber, setNumber, CONSTANTS.ABSENT_NO_EXCUSE);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <img
            src={OutZoneNoExcuseImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid item xs={8}>
          <AdminBoxSpan name="Absent No Excuse" number={inZones} />
        </Grid>
      </Grid>
    </Box>
  );
}

export function InZoneWorkAway() {
  const [outZones, setNumber] = useState(0);
  useEffect(() => {
    getDataAndSet(getOutZoneNumber, setNumber, CONSTANTS.IN_ZONE_REMOTE);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }} className="adminBox">
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <img
            src={InZoneWorkAwayImg}
            style={{ width: "5em" }}
            className="iconStyle"
            alt="BRUJH"
          ></img>
        </Grid>
        <Grid
          item
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          xs={8}
        >
          <AdminBoxSpan name="In Zone work away" number={0} />
        </Grid>
      </Grid>
    </Box>
  );
}
