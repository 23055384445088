import axios_api from "../../services/api";
import { isDefined } from "../../services/library";
import { adminData } from "../store";
import { Indexable, ModelAPI, Selector } from "./api";

export class Department implements ModelAPI, Indexable {
  id: number;
  name: string;
  arrival: number;
  leave: number;
  color: string;
  lat: number;
  lng: number;
  radius: number;

  constructor(
    id: number = undefined,
    name: string = undefined,
    arrival: number = undefined,
    leave: number = undefined,
    color: string = undefined,
    lat: number = undefined,
    lng: number = undefined,
    radius: number = undefined
  ) {
    this.id = id;
    this.name = name;
    this.arrival = arrival;
    this.leave = leave;
    this.color = color;
    this.lat = lat;
    this.lng = lng;
    this.radius = radius;
  }
  getID(): number {
    return this.id;
  }
  getName(): string {
    return this.name;
  }
  async create() {
    const thisToBeSent = {
      company_id: adminData.getCompanyId(),
      const_Arrival_time: this.arrival,
      const_Leave_time: this.leave,
      dep_name: this.name,
      lat: this.lat,
      lng: this.lng,
      radius: this.radius,
      message: "First Message",
      color: this.color,
    };
    try {
      await axios_api.post("/store-dep", thisToBeSent);
      return true;
    } catch {
      return false;
    }
  }
  delete() {
    throw new Error("Method not implemented.");
  }
  update() {
    throw new Error("Method not implemented.");
  }
}

export class Departments implements Selector<Department> {
  private departments: Department[];
  private static instance: Departments = null;

  private constructor() {
    this.departments = null;
  }
  getByID(id): Department {
    return this.departments.filter((dep) => {
      return dep.id == id;
    })[0];
  }

  public async getDepartments() {
    if (this.departments === null) await this.requestDepartments();
    return this.departments;
  }
  public reset() {
    this.departments = null;
  }

  private async requestDepartments() {
    const { data } = await axios_api.get("/get-deps", {
      headers: { Authorization: `Bearer ${adminData.getToken()}` },
    });
    this.departments = [];
    for (let det of data) {
      this.departments.push(
        new Department(
          det.id,
          det.dep_name,
          det.const_Arrival_time,
          det.const_Leave_time,
          det.color !== "" ? det.color : "#000000",
          parseFloat(det.lat),
          parseFloat(det.lng),
          parseInt(det.radius)
        )
      );
    }
  }

  public static getDepartmentSingleton(): Departments {
    if (Departments.instance === null) Departments.instance = new Departments();
    return Departments.instance;
  }
}
