import * as React from "react";

import { EmployeeContext } from "./PayrollSingleEmployee";
import { MonthlyStatus, MultipleMonthlyStatus } from "../../../store/Models/MonthlyStatus";
import StatsTable, { SingleMonth } from "./StatsTable";

const RightWidget = () => {
  const employee = React.useContext(EmployeeContext);
  const headStyle = { fontSize: "12pt", fontWeight: 800 };
  return (
    <div style={{margin:"2em"}}>
      <SingleMonth monthlyData={employee.getMonthlyStatus()} salary={employee.salary}/>
      <PreviousData />
    </div>
  );
};
export default RightWidget;

const PreviousData= ()=>{
  const employee= React.useContext(EmployeeContext);
  const monthlyStatus= new MultipleMonthlyStatus(employee);
  const[queries,setQueries]= React.useState([]);
  React.useEffect(()=>{
    const fetchData=async()=>{
      setQueries(await monthlyStatus.getMonthlyStatus());
      // const query= new MonthlyStatus(
      //   10,50,30,25,14,55,65,89
      // );
      // query.setTime(new Date().toDateString())
      // setQueries([query])
      console.log(queries);
    }
    fetchData();
  },[])

  return (
    <>
      {queries.length> 0 && <StatsTable qeuries={queries} salary={employee.salary}/>}
    </>
  )
}
