import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { PIE_LABELS, UserData } from "../../../../constants/data";
import Chart from "chart.js/auto";
import { Box, Grid } from "@mui/material";
import "../../css/adminBox.css";
import "../../css/charts.css";
import { observer } from "mobx-react-lite";
import {ArcElement} from 'chart.js'
import { SecondDashboard } from "../../../../store/Dashboards/SecondDashboard";
Chart.register(ArcElement);

const CONSTANTS= {
  IN_ZONE_LATE:"InZone-late",
  ABSENT_NO_EXCUSE:"Absent-No excuse",
  ABSENT_HOLIDAY:"Absent-Holiday",
  IN_ZONE_REMOTE:"OutZone-Work away"
}

const colors = {
  IN_ZONE_REMOTE: "#817F78",
  ABSENT_NO_EXCUSE: "#D86F2B",
  IN_ZONE_LATE: "#60A7A3",
  ABSENT_HOLIDAY:"#2E3133"
};

type PieData= {
  dashboardData: SecondDashboard
};

const SecondPieChart = observer((data:PieData) => {
  // console.log(dashboardData.getAbsents())

  const [dat, setDat] = useState({
    labels: [CONSTANTS.IN_ZONE_LATE, CONSTANTS.ABSENT_NO_EXCUSE, CONSTANTS.IN_ZONE_REMOTE, CONSTANTS.ABSENT_HOLIDAY],
    datasets: [
      {
        label: "Daily Company Statistics",
        data: [
          data.dashboardData.getInZoneLate(),
          data.dashboardData.getAbsentNoExcuse(),
          data.dashboardData.getInZoneRemote(),
          data.dashboardData.getAbsentHoliday()
        ],
        backgroundColor: [colors.IN_ZONE_LATE, colors.ABSENT_NO_EXCUSE, colors.IN_ZONE_REMOTE,colors.ABSENT_HOLIDAY],
      },
    ],
    options: {
      padding: 0,
    },
  });
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        // left: 100,
        // right: 100,
        bottom:0,
        top:0
      },
    },
    // scaleShowValues: true,
    radius: 80,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box className="adminBox" style={{ padding: 5, paddingBottom:0, height: "100%" }}>
      <h4 id="chart-label">Daily Company Statistics</h4>
      <Grid container spacing={10}>
        <Grid item md={4} xs={12}>
          <ul id="pieLabels">
            <li style={{ color: colors.IN_ZONE_LATE }}>
              <span>{CONSTANTS.IN_ZONE_LATE}</span>
            </li>
            <li style={{ color: colors.ABSENT_NO_EXCUSE }}>
              <span>{CONSTANTS.ABSENT_NO_EXCUSE}</span>
            </li>
            <li style={{ color: colors.IN_ZONE_REMOTE }}>
              <span>{CONSTANTS.IN_ZONE_REMOTE}</span>
            </li>
            <li style={{ color: colors.ABSENT_HOLIDAY }}>
              <span>{CONSTANTS.ABSENT_HOLIDAY}</span>
            </li>
          </ul>
        </Grid>
        <Grid item>
          <Pie
            data={dat}
            options={options}
            style={{position:"relative", bottom:"10%"}}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default SecondPieChart;
