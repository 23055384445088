import axios_api from "../../services/api";
import { Employee, Employees } from "./Employee";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export class MonthlyStatus {
  private paidAbsents: number;
  private shiftHours: number;
  private absentDays: number;
  private totalWorkingHours: number;
  private actualWorkingHours: number;
  private delay: number;
  private overtime: number;
  private attendanceDays: number;
  private date: Date;

  constructor(
    paidAbsents,
    shiftHours,
    absentDays,
    totalWorkingHours,
    actualWorkingHours,
    delay,
    overtime,
    attendanceDays
  ) {
    this.paidAbsents = paidAbsents;
    this.shiftHours = shiftHours;
    this.absentDays = absentDays;
    this.totalWorkingHours = totalWorkingHours;
    this.actualWorkingHours = actualWorkingHours;
    this.delay = delay;
    this.overtime = overtime;
    this.attendanceDays = attendanceDays;
  }
  getPaidAbsents = () => {
    if (this.paidAbsents === -1)
      this.paidAbsents =this.getTotalWorkingHours()/this.getShiftHours()-this.getAbsentDays()-this.getAttendanceDays();
    return this.paidAbsents;
  };
  getShiftHours = () => this.shiftHours;
  getAbsentDays = () => this.absentDays;
  getTotalWorkingHours = () => this.totalWorkingHours;
  getActualWorkingHours = ():number => this.actualWorkingHours;
  getNormalWorkingHours= ()=> {
    const x= this.getActualWorkingHours()-0 + (this.getShiftHours()*this.getPaidAbsents())-0;
    // console.log(`Actual Hours= ${this.getActualWorkingHours()}\t Shift Hours+Paid= ${this.getShiftHours()*this.getPaidAbsents()}`)
    // console.log(x);
    return x;
  };
  getDelay = () => this.delay;
  getOvertime = () => this.overtime;
  getAttendanceDays = (): number => this.attendanceDays;
  getMonth = () => monthNames[this.date.getMonth()];
  getMonthInNumber = (): number => this.date.getMonth();
  getDay = () => this.date.getDay();
  getYear = () => this.date.getFullYear();


  public setTime(time: string) {
    this.date = new Date(time);
  }
  computeDelay() {
    return this.delay + this.absentDays * this.shiftHours;
  }
  computeSalary(salary: number) {
    let normalWorkingHours = (this.actualWorkingHours - this.overtime) + this.getPaidAbsents()*this.getShiftHours();
    let totalSalary = normalWorkingHours * salary;
    totalSalary += this.overtime * salary * 1.5;
    totalSalary -= this.computeDelay() * 2 * salary;
    return totalSalary;// > 0 ? totalSalary : 0;
  }
  public async transferSalary(employee: Employee) {
    let object = {
      employee_id: employee.id,
      salary: this.computeSalary(employee.salary),
      total_working_hours: this.totalWorkingHours,
      actual_working_hours: this.actualWorkingHours,
      delay_hours: this.delay,
      overtime_hours: this.overtime,
      absent_days: this.absentDays,
      attend_days: this.getAttendanceDays(),
    };
    console.log(object)
    try{
    await axios_api.post("/transfersalary", object);
    Employees.getSingletonEmployees().resetEmployees();
    }catch(E){
      alert('ERROR!');
      console.log(E);
    }
  }
}

export class MultipleMonthlyStatus {
  private employee: Employee;
  private queries: MonthlyStatus[] = [];

  constructor(employee: Employee) {
    this.employee = employee;
  }

  async getMonthlyStatus() {
    if (this.queries.length > 0) return this.queries;
    const { data } = await axios_api.get(
      "/getMonthlyProductivity/" + this.employee.id
    );
    for (let i of data) {
      let monthStatus = new MonthlyStatus(
        -1,
        this.employee.getMonthlyStatus().getShiftHours(),
        i.absent_days,
        i.total_working_hours,
        i.actual_working_hours,
        i.delay_hours,
        i.overtime_hours,
        i.attend_days
      );
      // console.log(i);
      monthStatus.setTime(i.created_at);
      this.queries.push(monthStatus);
    }
    return this.queries;
  }

  getTotalAbsent(): Number[] {
    let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i of this.queries) {
      arr[i.getMonthInNumber()] =
        -1 * (-1 * arr[i.getMonthInNumber()] - i.getAbsentDays());
    }
    console.log(arr);
    return arr;
  }
  getTotalAttendance(): Number[] {
    let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    arr.fill(0);
    for (let i of this.queries) {
      console.log(i);
      console.log(i.getMonthInNumber());
      arr[i.getMonthInNumber()] =
        -1 * (-1 * arr[i.getMonthInNumber()] - i.getAttendanceDays());
    }
    console.log(arr);
    return arr;
  }
}
