import { Grid } from "@mui/material";
import LineChart from "./LineChart";
import Box from "@mui/material/Box";
import "./css/adminBox.css";
import { CompleteDashboard, DailyDashboard, ZoneTypesDashboard } from "./Charts/CompleteDashboard";

const mapBoxes = (Element) => {
  return (
    <Grid item xs={12} md={6}>
      <Element />
    </Grid>
  );
};
const DashStats = () => {

  return (
    <Box flexGrow={1}>
      <div>
        <DashboardComponents completeDashboard={new DailyDashboard()}/>
      </div>
      <div>
        <DashboardComponents completeDashboard={new ZoneTypesDashboard()} />
      </div>

      <Box marginTop={5} width="60%" position={"relative"} left="10%">
        <LineChart />
      </Box>
    </Box>
  );
};

export default DashStats;

type DashboardComponentData = {
  completeDashboard: CompleteDashboard
};

const DashboardComponents = (stats: DashboardComponentData) => {
  return (
    <Grid item container spacing={5} marginBottom={5} padding={2}>
      <Grid item xs={1}></Grid>
      <Grid item container xs={8} direction="column" spacing={3}>
        <Grid item>
          <Box height="50%" margin={0}>
            <Grid container spacing={1}>
              {stats.completeDashboard.getFirstAdminBoxes().map(mapBoxes)}
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box height="50%" margin={0}>
            <Grid container spacing={1}>
              {stats.completeDashboard.getSecondAdminBoxes().map(mapBoxes)}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={1}></Grid>

      <Grid item xs={10} md={8} style={{ marginTop: 10 }}>
        {stats.completeDashboard.getPieChart()}
      </Grid>
    </Grid>
  );
};
