export const Links= {
    home: "/",
    dashboard: "/dash",
    map: "/map",
    employeeList:"/emp-list",
    employeeData: "/:id",
    departmentList: "/dep-list",
    sendMessage: '/send-msg',
    messagesList: '/msg-list',
    createNew: '/create-new',
    payroll:'/payroll'
}