import axios_api from "../../../services/api";
import { adminData } from "../../store";
import { Department } from "../Department";
import { Employee } from "../Employee";


export abstract class Message<T extends any>{
    private messageID;
    private adminID:number;
    private messageText:string;

    constructor(messageText,admin_id= -1){
        this.messageText= messageText;
        this.adminID= admin_id===-1? adminData.getId(): admin_id;
    }
    public abstract getReceiver():string;
    public abstract getType():string;


    public setID(id:number){this.messageID= id;}
    public getID=()=>this.messageID;
    public getText=()=>this.messageText;
    abstract sendMessage ();
    abstract getMessage():T;
    protected async sendTheMessage ( api_url:string){
        await axios_api.post(api_url, 
            {
            admin_id:adminData.getId(),
            text:this.messageText
        });
    }
}

export class PrivateMessage extends Message<PrivateMessage>{
    
    private employee:Employee;

    constructor(employee:Employee,messageText:string,admin_id:number= -1){
        super(messageText,admin_id=-1);
        this.employee= employee;
    }
    public getReceiver(): string {
        return this.employee.getName();
    }
    public getType=(): string =>  "Private";
    async sendMessage() {
        const api_url= `/msgemp/${this.employee.id}`;
        await super.sendTheMessage(api_url);
    }
    getMessage(): PrivateMessage {
        throw new Error("Method not implemented.");
    }
}
export class DepartmentMessage extends Message<DepartmentMessage>{
    
    private department:Department;

    constructor(department:Department, messageText:string, admin_id:number= -1){
        super(messageText,admin_id=-1);
        this.department=department;
    }
    public getReceiver(): string {
        return this.department.getName();
    }
    public getType=(): string =>  "Department";

    
    async sendMessage() {
        const api_url= `/msgdep/${this.department.id}`;
        await super.sendTheMessage(api_url);
    }
    getMessage(): DepartmentMessage {
        return this;
    }
}
export class AnnouncementMessage extends Message<AnnouncementMessage>{
    

    constructor(messageText, admin_id=-1){
        super(messageText,admin_id);
    }

    public getReceiver(): string {
        return "Announcement";
    }
    public getType=(): string =>  "Announcement";

    async sendMessage() {
        const api_url= `/announc`;
        await super.sendTheMessage(api_url);
    }
    getMessage(): AnnouncementMessage {
        throw new Error("Method not implemented.");
    }
}