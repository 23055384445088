import React from "react";
import { useNavigate } from "react-router";
import { Links } from "../../constants/links";
import { CheckedInEmployees } from "../../store/Models/CheckedInEmployees";

type Data={
  counter:number,
  setter:Function
}
const RefreshButton = (data:Data) => {
  const navigate= useNavigate();
  return (
    <>
      <button
        style={{
          border: "#60A7A3 solid 1px",
          borderRadius: 5,
          width: "50%",
          height: "100%",
          color: "#60A7A3",
          fontFamily: "Exo 2",
          fontWeight: "bold",
          padding: 10,
        }}
        onClick={()=>{
            CheckedInEmployees.getSingletonInstance().reset();
            data.setter(data.counter+1);
        }}
      >
        Refresh
      </button>
    </>
  );
};

export default RefreshButton;
