import { AdminData } from "../store/Admin";
import axios_api from "./api";
import { adminData } from "../store/store";

export const getEmployeesList = async () => {
  const { data } = await axios_api.get("get-employees", {
    headers: { Authorization: `Bearer ${adminData.getToken()}` },
  });
  // console.log(data)
  return data;
};

export const getAllAdminMessages = async () => {
  try {
    const { data } = await axios_api.get("/msg", {
      headers: { Authorization: `Bearer ${adminData.getToken()}` },
    });
    return data;
  } catch (e) {
    return null;
  }
};

export const getCurrentAbsentNumber = async () => {
  return await getData("/outZoneNoexcuse");
};
export const getInZoneLateNumber = async () => {
  let data= await getData("/inZoneLate");
  return data;
};
export const getNumberAbsentHoliday = async () => {
  return await getData("/outZoneholiday");
};
export const getTotalNumberOfEmployees = async () => { //TESTED
  let x = await getData("/number-of-employees");
  return x;
};
export const getTotalNumberOfAbsents = async () => {
  let data =await getData("/absence_today");
  return data.count;
};
export const getOutZoneNumber = async () => {
  let x = await getData("/count-out/"+adminData.getCompanyId());
  // console.log(x);
  return x.length;
};
export const getInZoneNumber = async () => {
  let data= await getData("/count-in/"+adminData.getCompanyId());
  console.log(data);
  console.log(data.length);
  return data.length;
};

const getData = async (url) => {
  try {
    const { data } = await axios_api.get(url, {
      headers: { Authorization: `Bearer ${adminData.getToken()}` },
    });
    return data;
  } catch (E){
    console.log(E);
    return -1;
  }
};

export const getMonthlyIns = async (url) => {
  const { data } = await axios_api.get("/calcIn", {
    headers: { Authorization: `Bearer ${adminData.getToken()}` },
  });
  // console.log(data);
  return data;
};
export const getMonthlyOuts = async (url) => {
  const { data } = await axios_api.get("/calcOut", {
    headers: { Authorization: `Bearer ${adminData.getToken()}` },
  });
  // console.log(data);
  return data;
};
