import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import ImageViewer from "../../../components/ImageViewer";
import Title from "../../../components/title/Title";
import { Links } from "../../../constants/links";
import { Employee, Employees } from "../../../store/Models/Employee";
import {
  MonthlyStatus,
  MultipleMonthlyStatus,
} from "../../../store/Models/MonthlyStatus";
import RightWidget from "./RightWidget";
import StatsTable from "./StatsTable";

export const EmployeeContext = createContext(new Employee());
interface StateType {
  id: number;
}

const PayrollSingleEmployee = () => {
  const { state } = useLocation();
  console.log(state);
  console.log((state as StateType).id);
  const employee = Employees.getSingletonEmployees().getByID(
    (state as StateType).id
  );
  console.log(employee);
  return (
    <div>
      <Title
        titleName={"Payroll"}
        backLink={`${Links.dashboard}${Links.payroll}`}
      />
      <EmployeeContext.Provider value={employee}>
        <div style={{ marginTop: 20, display: "flex" }}>
          <LeftWidget />
          <div style={{ width: "75%" }}>
            <RightWidget />
          </div>
        </div>
      </EmployeeContext.Provider>
    </div>
  );
};

export default PayrollSingleEmployee;

const LeftWidget = () => {
  let employee = useContext(EmployeeContext);
  console.log(employee);

  const transferSalary = async () => {
    try {
      await employee.transferSalary();
      alert("Salary Sucessfully Transferred!");
    } catch (E) {
      alert("Error occured!");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "190px",
        display: "inline-block",
        // border:"black solid 1px"
      }}
    >
      <div>
        <ImageViewer employee={employee} isUpload={false} />
      </div>
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            position: "relative",
            right: "5%",
            fontFamily: "Exo 2",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16pt",
            lineHeight: "19px",
            color: "#2E3133",
            marginBottom: 1,
          }}
        >
          {employee.name}
        </p>
        <p
          style={{
            position: "relative",
            right: "5%",
            marginTop: 10,
            fontFamily: "Exo 2",
            fontStyle: "normal",
            fontWeight: 200,
            fontSize: "12pt",
            lineHeight: "19px",
            color: "#2E3133",
          }}
        >
          {employee.position}
        </p>
        <p>
          Working Salary:{" "}
          <span style={{ color: "#60A7A3" }}>{employee.salary}$/hr</span>
        </p>
      </div>
      <div style={{ marginTop: 20 }}>
        <button
          className="buttons"
          style={{
            width: "100%",
            fontSize: "13pt",
            padding: 0,
          }}
          onClick={transferSalary}
        >
          Transfer Total Salary
        </button>
        <button
          className="buttons"
          style={{
            width: "100%",
            fontSize: "13pt",
            padding: 0,
            marginTop: 20,
            backgroundColor: "#ffffff",
            color: "#60A7A3",
            border: "#60A7A3 solid 1px",
          }}
        >
          Export File
        </button>
      </div>
    </div>
  );
};
